import { micromark } from "micromark";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EmbargoGrid from "./EmbargoGrid";
import NoticeGrid from "./NoticeGrid";
import Embargo from "../../../models/Embargo";
import { useEffect, useState } from "react";
import Notice from "../../../models/Notice";
import { useApi } from "../../../Providers/ApiProvider";

interface IProps {
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export const AOS_MNAE = (props: IProps) => {
  const API = useApi();
  const DA = API.General;

  dayjs.extend(utc);

  const Today = dayjs.utc();

  const [TheseEmbargoes, setTheseEmbargoes] = useState(Array<Embargo>);
  const [EditingEmbargo, setEditingEmbargo] = useState(false);
  const [EditingEmbargoID, setEditingEmbargoID] = useState(undefined);
  const [ThisEmbargo, setThisEmbargo] = useState(Embargo.prototype);

  const [TheseNotices, setTheseNotices] = useState(Array<Notice>);
  const [EditingNotice, setEditingNotice] = useState(false);
  const [EditingNoticeID, setEditingNoticeID] = useState(undefined);
  const [ThisNotice, setThisNotice] = useState(Notice.prototype);

  useEffect(() => {
    console.log("useEffect [DA]");
    console.log(DA);
    Load();
  }, [DA]);

  useEffect(() => {
    console.log("useEffect [EditingEmbargoID]");
    console.log(EditingEmbargoID);
    if (EditingEmbargoID !== undefined) {
      LoadEmbargoForEdit();
    }
  }, [EditingEmbargoID]);

  useEffect(() => {
    console.log("useEffect [EditingNoticeID]");
    console.log(EditingNoticeID);
    if (EditingNoticeID !== undefined) {
      LoadMessageForEdit();
    }
  }, [EditingNoticeID]);

  function AlterNoticeScopes(
    Add: string[],
    Remove: string[],
    Toggle: string[],
  ) {
    var clone = { ...ThisNotice };
    var newScopes = clone._scopes;

    Add.forEach((th) => {
      // if clone has NO Add members...
      if (!newScopes.some((ith) => ith === th)) {
        newScopes.push(th);
      }
    });

    Remove.forEach((th) => {
      // if clone has ANY Remove members...
      if (newScopes.some((ith) => ith === th)) {
        newScopes = newScopes.filter((iith) => iith !== th);
      }
    });

    Toggle.forEach((th) => {
      // if clone has ANY Remove members...
      if (newScopes.some((ith) => ith === th)) {
        newScopes = newScopes.filter((iith) => iith !== th);
      } else {
        newScopes.push(th);
      }
    });

    clone._scopes = newScopes;
    clone._scope = clone._scopes.join(",");

    setThisNotice(clone);
  }

  function NoticeHasScope(Scope: string) {
    return ThisNotice._scopes.some((th) => th === Scope);
  }

  function SubmitEmbargo(): void {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.CrupdateEmbargo",
        params: [
          "@EmbargoID",
          ThisEmbargo.EmbargoID,
          "@EnforcedFrom",
          ThisEmbargo.EnforcedFrom,
          "@EnforcedUntil",
          ThisEmbargo.EnforcedUntil,
          "@Scope",
          ThisEmbargo._scope,
          "@Author",
          "{%u}",
        ],
      }),
      "POST",
      true,
    ).then(
      (data) => {
        setTimeout(() => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else {
            props.DeregisterError("AOS2_Manage_Embargoes.Submit()");
            setEditingEmbargo(false);
            setEditingEmbargoID(undefined);
            setThisEmbargo(Embargo.prototype);
            Load();
          }

          if (_error !== "") {
            props.RegisterError("AOS2_Manage_Embargoes.Submit()", _error);
          }
        }, 300);
      },
      (reason) => {
        props.RegisterError("AOS2_Manage_Embargoes.Submit()", reason);
      },
    );
  }

  function SubmitNotice(): void {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.CrupdateNotice",
        params: [
          "@NoticeID",
          ThisNotice.NoticeID,
          "@Title",
          ThisNotice.Title,
          "@Body_Plain",
          ThisNotice.Body_Plain,
          "@Body_HTML",
          micromark(ThisNotice?.Body_Plain ?? ""),
          "@VisibleFrom",
          ThisNotice.VisibleFrom,
          "@VisibleUntil",
          ThisNotice.VisibleUntil,
          "@EmbargoID",
          ThisNotice.EmbargoID,
          "@Scope",
          ThisNotice._scope,
          "@Author",
          "{%u}",
        ],
      }),
      "POST",
      true,
    ).then(
      (data) => {
        setTimeout(() => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else {
            props.DeregisterError("AOS_MNAE.Submit()");
            setEditingNotice(false);
            setEditingNoticeID(undefined);
            setThisNotice(Notice.prototype);
            Load();
          }

          if (_error !== "") {
            props.RegisterError("AOS_MNAE.Submit()", _error);
          }
        }, 300);
      },
      (reason) => {
        props.RegisterError("AOS_MNAE.Submit()", reason);
      },
    );
  }

  function NewEmbargo() {
    setEditingEmbargo(true);
    setEditingEmbargoID(undefined);
    setThisEmbargo({
      EmbargoID: "",
      EnforcedFrom: undefined,
      EnforcedUntil: undefined,
      _scope: "UCAS",
      _scopes: ["UCAS"],
      Author: "",
    });
  }

  function NewNotice(
    _scopes?: string[],
    _startDate?: dayjs.Dayjs,
    _enddate?: dayjs.Dayjs,
  ) {
    setEditingNotice(true);

    setEditingNoticeID(undefined);

    var _conc_scopes = ["AOS", "Banner"];

    setThisNotice({
      NoticeID: "",
      Title: "Service Announcement",
      Body_Plain: "",
      Body_HTML: "",
      VisibleFrom: _startDate,
      VisibleUntil: _enddate,
      EmbargoID: "",
      _scope: _conc_scopes.join(","),
      _scopes: _conc_scopes,
    });
  }

  function EditEmbargo(_embargoID: string) {
    setEditingEmbargo(true);
    setEditingEmbargoID(_embargoID);
    window.scrollTo(0, 0);
  }

  function EditNotice(_noticeID: string) {
    setEditingNotice(true);
    setEditingNoticeID(_noticeID);
    window.scrollTo(0, 0);
  }

  function DeleteThisEmbargo(_embargoID: string) {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.DeleteEmbargo",
        params: ["@EmbargoID", _embargoID],
      }),
      "POST",
      true,
    ).then(() => {
      // mis-live-r time...
      setTimeout(() => {
        Load();
      }, 300);
    });
  }

  function DeleteThisNotice(_noticeID: string): void {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.DeleteNotice",
        params: ["@NoticeID", _noticeID],
      }),
      "POST",
      true,
    ).then(() => {
      // mis-live-r time...
      setTimeout(() => {
        Load();
      }, 300);
    });
  }

  function LoadEmbargoForEdit(): void {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.GetEmbargoForEditing",
        params: ["@EmbargoID", EditingEmbargoID],
      }),
      "POST",
      true,
    ).then(
      (data) => {
        console.log("GetEmbargoForEditing -->");
        console.log(data);
        var _error: string = "";
        if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
          _error = data[0][0]["ERROR"];
        } else if (data !== undefined) {
          if (data.length > 0) {
            if (data[0] !== undefined) {
              if (data[0].length === 1) {
                props.DeregisterError("AOS2_Manage_Embargoes.Load()");
                const temp = {
                  EmbargoID: data[0][0].EmbargoID,
                  EnforcedFrom: dayjs.utc(data[0][0].EnforcedFrom),
                  EnforcedUntil: dayjs.utc(data[0][0].EnforcedUntil),
                  _scope: data[0][0].Scope,
                  _scopes: (data[0][0].Scope as string)
                    .split(",")
                    .filter((th) => th !== ""),
                  Author: data[0][0].Author,
                };
                setEditingEmbargo(true);
                setThisEmbargo(temp);
              } else {
                _error =
                  "AOS2_Manage_Embargoes.Reset() - Data returned an unexpected number of rows.";
              }
            } else {
              _error =
                "AOS2_Manage_Embargoes.Reset() - Data returned a null singleton.";
            }
          }
          if (data.length <= 0) {
            _error =
              "AOS2_Manage_Embargoes.Reset() - Data returned an unexpected number of tables.";
          }
        } else {
          _error = "AOS2_Manage_Embargoes.Reset() - Data returned undefined.";
        }

        if (_error !== "") {
          props.RegisterError("AOS2_Manage_Embargoes.Reset()", _error);
        }
      },
      (reason) => {
        props.RegisterError("AOS2_Manage_Embargoes.Reset()", reason);
      },
    );
  }

  function LoadMessageForEdit(): void {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.GetNoticeForEditing",
        params: ["@NoticeID", EditingNoticeID],
      }),
      "POST",
      true,
    ).then(
      (data) => {
        console.log("GetNoticeForEditing -->");
        console.log(data);
        console.log(data[0]);
        console.log(data[0][0]);
        var _error: string = "";
        if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
          _error = data[0][0]["ERROR"];
        } else if (data !== undefined) {
          if (data.length > 0) {
            if (data[0] !== undefined) {
              if (data[0].length === 1) {
                props.DeregisterError("AOS_MNAE.Load()");
                const temp = {
                  NoticeID: data[0][0].NoticeID,
                  Title: data[0][0].Title,
                  Body_Plain: data[0][0].Body_Plain,
                  Body_HTML: data[0][0].Body_HTML,
                  VisibleFrom: dayjs.utc(data[0][0].VisibleFrom),
                  VisibleUntil: dayjs.utc(data[0][0].VisibleUntil),
                  EmbargoID: data[0][0].EmbargoID,
                  _scope: data[0][0].Scope,
                  _scopes: (data[0][0].Scope as string)
                    .split(",")
                    .filter((th) => th !== ""),
                };
                setThisNotice(temp);
                setEditingNotice(true);
              } else {
                _error =
                  "AOS_MNAE.Reset() - Data returned an unexpected number of rows.";
              }
            } else {
              _error = "AOS_MNAE.Reset() - Data returned a null singleton.";
            }
          }
          if (data.length <= 0) {
            _error =
              "AOS_MNAE.Reset() - Data returned an unexpected number of tables.";
          }
        } else {
          _error = "AOS_MNAE.Reset() - Data returned undefined.";
        }

        if (_error !== "") {
          props.RegisterError("AOS_MNAE.Reset()", _error);
        }
      },
      (reason) => {
        props.RegisterError("AOS_MNAE.Reset()", reason);
      },
    );
  }

  function Load(): void {
    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.GetAllEmbargoes",
        params: [],
      }),
      "POST",
      true,
    ).then(
      (data) => {
        //console.log("GetAllEmbargoes complete:");
        //console.log(data);
        var _error: string = "";
        if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
          _error = data[0][0]["ERROR"];
        } else if (data !== undefined) {
          if (data.length > 0) {
            if (data[0] !== undefined) {
              if (data[0].length > 0) {
                props.DeregisterError("AOS2_Manage_Embargoes.Load()");
                var danns: Embargo[] = [];
                data[0].forEach((th) => {
                  danns = danns.concat({
                    EmbargoID: th.EmbargoID,
                    EnforcedFrom: dayjs.utc(th.EnforcedFrom),
                    EnforcedUntil: dayjs.utc(th.EnforcedUntil),
                    _scope: th.Scope,
                    _scopes: (th.Scope as string)
                      .split(",")
                      .filter((th) => th !== ""),
                    Author: th.Author,
                  });
                });
                setTheseEmbargoes(danns);
              } else {
                _error =
                  "AOS2_Manage_Embargoes.Load() - Data returned an unexpected number of rows.";
              }
            } else {
              _error =
                "AOS2_Manage_Embargoes.Load() - Data returned a null singleton.";
            }
          }
          if (data.length <= 0) {
            _error =
              "AOS2_Manage_Embargoes.Load() - Data returned an unexpected number of tables.";
          }
        } else {
          setTheseEmbargoes([]);
        }

        if (_error !== "") {
          props.RegisterError("AOS2_Manage_Embargoes.Load()", _error);
        }
      },
      (reason) => {
        props.RegisterError("AOS2_Manage_Embargoes.Load()", reason);
      },
    );

    DA.post(
      "/api/sproc/post",
      JSON.stringify({
        sproc: "sputilities.webui.GetAllNotices",
        params: [],
      }),
      "POST",
      true,
    ).then(
      (data) => {
        //console.log("GetAllNotices complete:");
        //console.log(data);
        var _error: string = "";
        if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
          _error = data[0][0]["ERROR"];
        } else if (data !== undefined) {
          if (data.length > 0) {
            if (data[0] !== undefined) {
              if (data[0].length > 0) {
                props.DeregisterError("AOS_MNAE.Load()");
                var danns: Notice[] = [];
                data[0].forEach((th) => {
                  danns = danns.concat({
                    NoticeID: th.NoticeID,
                    Title: th.Title,
                    Body_Plain: th.Body_Plain,
                    Body_HTML: th.Body_HTML,
                    VisibleFrom: dayjs.utc(th.VisibleFrom),
                    VisibleUntil: dayjs.utc(th.VisibleUntil),
                    EmbargoID: th.EmbargoID,
                    _scope: th.Scope,
                    _scopes: (th.Scope as string)
                      .split(",")
                      .filter((th) => th !== ""),
                  });
                });
                setTheseNotices(danns);
              } else {
                _error =
                  "AOS_MNAE.Load() - Data returned an unexpected number of rows.";
              }
            } else {
              _error = "AOS_MNAE.Load() - Data returned a null singleton.";
            }
          }
          if (data.length <= 0) {
            _error =
              "AOS_MNAE.Load() - Data returned an unexpected number of tables.";
          }
        } else {
          _error = "AOS_MNAE.Load() - Data returned undefined.";
        }

        if (_error !== "") {
          props.RegisterError("AOS_MNAE.Load()", _error);
        }
      },
      (reason) => {
        props.RegisterError("AOS_MNAE.Load()", reason);
      },
    );
  }

  console.log(ThisNotice);

  return (
    <div className="NAEManager">
      <h1>Manage Notices and Embargoes</h1>
      <p className="Introduction">
        Use this page to schedule notices, announcements, and UCAS Embargoes.
      </p>

      <div className="EmbargoManager">
        {EditingEmbargo === true ? (
          <div id="AOSEmbargoEditor" className="ms-Grid-row box">
            <p>
              <label className="inputLabel" htmlFor="EnforcedFrom">
                Enforced From:
              </label>
              <input
                className="dateinput"
                id="EnforcedFrom"
                type="datetime-local"
                value={
                  ThisEmbargo.EnforcedFrom !== undefined
                    ? ThisEmbargo.EnforcedFrom.local().format(
                        "YYYY-MM-DDTHH:mm",
                      )
                    : undefined
                }
                onChange={(e) => {
                  var clone = { ...ThisEmbargo };
                  clone.EnforcedFrom = dayjs(e.target.value);
                  setThisEmbargo(clone);
                }}
              />
            </p>
            <p>
              <label className="inputLabel" htmlFor="EnforcedUntil">
                Enforced Until:
              </label>
              <input
                className="dateinput"
                id="EnforcedUntil"
                type="datetime-local"
                value={
                  ThisEmbargo.EnforcedUntil !== undefined
                    ? ThisEmbargo.EnforcedUntil.local().format(
                        "YYYY-MM-DDTHH:mm",
                      )
                    : undefined
                }
                onChange={(e) => {
                  var clone = { ...ThisEmbargo };
                  clone.EnforcedUntil = dayjs(e.target.value);
                  setThisEmbargo(clone);
                }}
              />
            </p>
            <p>
              <b>Concurrent Notices:</b>
            </p>
            <p>An Embargo should have:</p>
            <ul>
              <li>
                One 'Current Applicants' notice, which replaces the 'Current
                Applicants' panel (which cannot be used during Embargo).
              </li>
              <li>
                One 'Applicant Page' notice, which replaces embargo-masked
                components on the Applicant Pages visible to Agents.
              </li>
              <li>
                One optional 'Top Banner' notice, which will show at the top of
                the Agent Portal.
              </li>
            </ul>
            <p>
              {TheseNotices.filter((th) => {
                (th.VisibleFrom < ThisEmbargo?.EnforcedUntil &&
                  ThisEmbargo?.EnforcedUntil < th.VisibleUntil) ||
                  (th.VisibleFrom < ThisEmbargo?.EnforcedFrom &&
                    ThisEmbargo?.EnforcedFrom < th.VisibleUntil);
              }).map((th, i) => {
                return (
                  <div key={"conc_emb_" + i.toString()}>{th.Body_HTML}</div>
                );
              })}
            </p>
            {ThisEmbargo.EnforcedFrom == null ||
            ThisEmbargo.EnforcedUntil == null ? (
              <input id="submit" type="button" value="Save" disabled />
            ) : (
              <input
                id="submit"
                type="button"
                value="Save"
                onClick={() => {
                  SubmitEmbargo();
                }}
              />
            )}
            <input
              id="reset"
              type="button"
              value="Reset"
              onClick={() => {
                LoadEmbargoForEdit();
                Load();
              }}
            />
            <input
              id="clear"
              type="button"
              value="Clear"
              onClick={() => {
                setEditingEmbargo(false);
                setThisEmbargo(Embargo.prototype);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {EditingNotice === true ? (
        <div id="AOSMessageEditor" className="ms-Grid-row box">
          <h2>Editing Notice</h2>
          <label className="inputLabel" htmlFor="title">
            Title:
          </label>
          <input
            className="textinput"
            id="title"
            type="text"
            value={ThisNotice.Title}
            onChange={(e) => {
              var clone = { ...ThisNotice };
              clone.Title = e.target.value;
              setThisNotice(clone);
            }}
          />
          <label className="inputLabel" htmlFor="body">
            Body:{" "}
            <a href="https://commonmark.org/help/" target="_blank">
              (Markdown Help)
            </a>
          </label>
          <textarea
            className="textinput"
            rows={7}
            id="body"
            value={ThisNotice.Body_Plain}
            onChange={(e) => {
              var clone = { ...ThisNotice };
              clone.Body_Plain = e.target.value;
              setThisNotice(clone);
            }}
          />
          <div className="ScopeSetters PASScopeSetter">
            <div className="PASSetterLabel">Audience</div>
            <div
              className={
                "PASSetter" +
                (!ThisNotice?._scopes?.includes("PASOnly") &&
                !ThisNotice?._scopes?.includes("NonPASOnly")
                  ? " Active"
                  : "")
              }
              onClick={() => {
                AlterNoticeScopes([], ["PASOnly", "NonPASOnly"], []);
              }}
            >
              All
            </div>
            <div
              className={
                "PASSetter" +
                (ThisNotice?._scopes?.includes("PASOnly") ? " Active" : "")
              }
              onClick={() => {
                AlterNoticeScopes([], ["NonPASOnly"], ["PASOnly"]);
              }}
            >
              PAS Only
            </div>
            <div
              className={
                "PASSetter" +
                (ThisNotice?._scopes?.includes("NonPASOnly") ? " Active" : "")
              }
              onClick={() => {
                AlterNoticeScopes([], ["PASOnly"], ["NonPASOnly"]);
              }}
            >
              Non-PAS Only
            </div>
            <div className="PASSetterDescription">
              {ThisNotice?._scopes?.includes("PASOnly")
                ? "This notice will only be shown to PAS Agents."
                : ThisNotice?._scopes?.includes("NonPASOnly")
                  ? "This notice will only be shown to non-PAS Agents."
                  : "This notice will be shown to all agents, whether PAS or not PAS."}
            </div>
          </div>
          <div className="ScopeSetter EmbargoLink">
            <div className="PASSetterLabel">Embargo Link</div>
            <div
              className={
                "PASSetter" +
                (ThisNotice?._scopes?.includes("Embargo") ? " Active" : "")
              }
              onClick={() => {
                if (ThisNotice?._scopes?.includes("Embargo")) {
                  var clone = { ...ThisNotice };
                  clone.EmbargoID = "";
                  setThisNotice(clone);
                }
                AlterNoticeScopes([], [], ["Embargo"]);
              }}
            >
              Attach
            </div>
            {ThisNotice?._scopes?.includes("Embargo") ? (
              <select
                value={ThisNotice?.EmbargoID ?? ""}
                onChange={(ev) => {
                  var clone = { ...ThisNotice };
                  clone.EmbargoID = ev.target.value;
                  setThisNotice(clone);
                }}
              >
                <option value="">-</option>
                {TheseEmbargoes.map((th, i) => {
                  return (
                    <option
                      key={"LinkEmbargo" + i.toString()}
                      value={th.EmbargoID}
                    >
                      Embargo {th.EmbargoID.toString()} -{" "}
                      {th.EnforcedFrom.toString()} to{" "}
                      {th.EnforcedUntil.toString()}
                    </option>
                  );
                })}
              </select>
            ) : (
              <></>
            )}
          </div>
          <div className="ScopeSetter Lifespan">
            <div className="PASSetterLabel">Lifespan</div>
            <label htmlFor="VisibleFrom">From:</label>
            <input
              className="dateinput"
              id="VisibleFrom"
              type="datetime-local"
              value={
                ThisNotice.VisibleFrom !== undefined
                  ? ThisNotice.VisibleFrom.local().format("YYYY-MM-DDTHH:mm")
                  : undefined
              }
              onChange={(e) => {
                var clone = { ...ThisNotice };
                clone.VisibleFrom = dayjs(e.target.value);
                setThisNotice(clone);
              }}
            />
            <label htmlFor="VisibleUntil">Until:</label>
            <input
              className="dateinput"
              id="VisibleUntil"
              type="datetime-local"
              value={
                ThisNotice.VisibleUntil !== undefined
                  ? ThisNotice.VisibleUntil.local().format("YYYY-MM-DDTHH:mm")
                  : undefined
              }
              onChange={(e) => {
                var clone = { ...ThisNotice };
                clone.VisibleUntil = dayjs(e.target.value);
                setThisNotice(clone);
              }}
            />
            {(ThisNotice?.EmbargoID ?? "" !== "") ? (
              <div
                className={
                  "PASSetter" +
                  (ThisNotice?.VisibleFrom?.utc().isSame(
                    TheseEmbargoes.find(
                      (th) => th.EmbargoID === ThisNotice.EmbargoID,
                    )?.EnforcedFrom?.utc(),
                  ) &&
                  ThisNotice?.VisibleUntil?.utc().isSame(
                    TheseEmbargoes.find(
                      (th) => th.EmbargoID === ThisNotice.EmbargoID,
                    )?.EnforcedUntil?.utc(),
                  )
                    ? " Active"
                    : "")
                }
                onClick={() => {
                  var clone = { ...ThisNotice };
                  var emb = TheseEmbargoes.find(
                    (th) => th.EmbargoID === ThisNotice.EmbargoID,
                  );
                  clone.VisibleUntil = emb.EnforcedUntil;
                  clone.VisibleFrom = emb.EnforcedFrom;
                  setThisNotice(clone);
                }}
              >
                Use Embargo Lifespan
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="ScopeSetter Locations">
            <div className="PASSetterLabel">Locations</div>

            <div
              className={
                "PASSetter" +
                (ThisNotice?._scopes?.includes("Banner") ? " Active" : "")
              }
              onClick={() => {
                AlterNoticeScopes([], ["Applicant", "CurrentApps"], ["Banner"]);
              }}
            >
              Top Banner
            </div>

            <div
              className={
                "PASSetter" +
                (ThisNotice?._scopes?.includes("Applicant") ? " Active" : "")
              }
              onClick={() => {
                AlterNoticeScopes([], ["Banner", "CurrentApps"], ["Applicant"]);
              }}
            >
              Applicant Page
            </div>

            <div
              className={
                "PASSetter" +
                (ThisNotice?._scopes?.includes("CurrentApps") ? " Active" : "")
              }
              onClick={() => {
                AlterNoticeScopes([], ["Banner", "Applicant"], ["CurrentApps"]);
              }}
            >
              Current Applications
            </div>
          </div>

          <div className="LocationDescription">
            <b>This notice will be shown:</b>
            <ul>
              {ThisNotice?._scopes?.includes("Banner") ? (
                <li>On the Notices banner at the top of the Agent Portal.</li>
              ) : (
                <></>
              )}
              {ThisNotice?._scopes?.includes("Applicant") ? (
                <li>
                  On the Applicant View page, in place of all components that
                  could show embargo-blocked information.
                </li>
              ) : (
                <></>
              )}
              {ThisNotice?._scopes?.includes("CurrentApps") ? (
                <li>
                  On the Agent Portal, in place of the embargo-blocked Current
                  Applicants link panel.
                </li>
              ) : (
                <></>
              )}
              {!ThisNotice?._scopes?.includes("Banner") &&
              !ThisNotice?._scopes?.includes("Applicant") &&
              !ThisNotice?._scopes?.includes("CurrentApps") ? (
                <li>Nowhere (please select a Location).</li>
              ) : (
                <></>
              )}
            </ul>
          </div>
          {ThisNotice.VisibleUntil == null ||
          ThisNotice.VisibleUntil == null ? (
            <input id="submit" type="button" value="Save" disabled />
          ) : (
            <input
              id="submit"
              type="button"
              value="Save"
              onClick={() => {
                SubmitNotice();
              }}
            />
          )}
          <input
            id="reset"
            type="button"
            value="Reset"
            onClick={() => {
              LoadMessageForEdit();
            }}
          />
          <input
            id="clear"
            type="button"
            value="Clear"
            onClick={() => {
              setEditingNotice(false);
              setThisNotice(Notice.prototype);
            }}
          />
        </div>
      ) : (
        ""
      )}
      {
        // Yes, we are setting dangerously here - but the input is from what the user has typed in.
        // If this poses a security risk, we already have an issue as they have access to the console.
        // The only time this might be a risk is when the user is editing an old message - which has
        // already gone through this display (or the attacker already has direct DB access and we are screwed)
      }
      {EditingNotice === true ? (
        <div id="AosMessagePreview" className="Notices">
          <div className="NoticesTitleBar">Notice Preview</div>
          <div className="Notice">
            {ThisNotice.Title !== undefined && ThisNotice.Title !== "" ? (
              <div className="NoticeTitle">{ThisNotice.Title}</div>
            ) : (
              ""
            )}
            <div
              className="NoticeBody"
              dangerouslySetInnerHTML={{
                __html: micromark(ThisNotice?.Body_Plain ?? ""),
              }}
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Tabs selectedTabClassName="active">
        <TabList>
          <Tab>Current and Future Notices</Tab>
          <Tab>Current and Future Embargoes</Tab>
          <Tab>Past Notices</Tab>
          <Tab>Past Embargoes</Tab>
        </TabList>
        <TabPanel>
          <NoticeGrid
            NewNotice={NewNotice}
            NewNoticeAllowed={true}
            EditNotice={EditNotice}
            DeleteNotice={DeleteThisNotice}
            Notices={TheseNotices.filter((th) =>
              dayjs().isBefore(th.VisibleUntil),
            )}
          ></NoticeGrid>
        </TabPanel>
        <TabPanel>
          <EmbargoGrid
            NewEmbargoAllowed={true}
            NewEmbargo={NewEmbargo}
            EditEmbargo={EditEmbargo}
            DeleteEmbargo={DeleteThisEmbargo}
            EditNotice={EditNotice}
            DeleteNotice={DeleteThisNotice}
            Embargoes={TheseEmbargoes.filter((th) =>
              dayjs().isBefore(th.EnforcedUntil),
            )}
            Notices={TheseNotices.filter((th) =>
              dayjs().isBefore(th.VisibleUntil),
            )}
          ></EmbargoGrid>
        </TabPanel>
        <TabPanel>
          <NoticeGrid
            NewNotice={NewNotice}
            NewNoticeAllowed={false}
            EditNotice={EditNotice}
            DeleteNotice={DeleteThisNotice}
            Notices={TheseNotices.filter((th) =>
              dayjs().isAfter(th.VisibleUntil),
            )}
          ></NoticeGrid>
        </TabPanel>
        <TabPanel>
          <EmbargoGrid
            NewEmbargoAllowed={false}
            NewEmbargo={NewEmbargo}
            EditEmbargo={EditEmbargo}
            DeleteEmbargo={DeleteThisEmbargo}
            EditNotice={EditNotice}
            DeleteNotice={DeleteThisNotice}
            Embargoes={TheseEmbargoes.filter((th) =>
              dayjs().isAfter(th.EnforcedUntil),
            )}
            Notices={TheseNotices.filter((th) =>
              dayjs().isAfter(th.VisibleUntil),
            )}
          ></EmbargoGrid>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AOS_MNAE;
