import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useUser } from "../../Providers/UserProvider";
import "../../assets/css/InvarAuditor.css";
import { useApi } from "../../Providers/ApiProvider";
import { Logging } from "../../Logging";
import { PublicClientApplication } from "@azure/msal-browser";
import { GetWorkOrderResponse } from "../../data/InvarDataModels/GetWorkOrderResponse";
import dayjs from "dayjs";
import {
  Mondrian,
  MondrianColumn,
  MondrianInputObject,
  MondrianSettings,
  SortOrder,
} from "@spt/mondrian/exports";

interface IProps {
  Logger: Logging;
  PCA: PublicClientApplication;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export function InvarAuditor(props: IProps) {
  const columns: MondrianColumn[] = [
    {
      HumanName: "Mechanism",
      MachineName: "ConveyanceName",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Identifier",
      MachineName: "InitialValue",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Uploaded By",
      MachineName: "RequestedBy",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Uploaded Date",
      MachineName: "RequestedDate",
      Filterable: false,
      Sortable: true,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Completed Date",
      MachineName: "FulfilledDate",
      Filterable: false,
      Sortable: true,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Status",
      MachineName: "Status",
      Filterable: false,
      Sortable: false,
      Groupable: true,
      Hideable: false,
    },
    {
      HumanName: "Action",
      MachineName: "Action",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "_ROW_PROCESSING",
      MachineName: "_ROW_PROCESSING",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
  ];

  const _settings: MondrianSettings = {
    FilterSettings: {
      Defaults: [],
    },
    GroupAndSortSettings: {
      ShowSubcount: true,
      Defaults: [
        {
          Ordinal: 1,
          MondrianColumn: {
            HumanName: "Completed Date",
            MachineName: "FulfilledDate",
            Filterable: false,
            Sortable: false,
            Groupable: false,
            Hideable: false,
          },
          Sort: SortOrder.Descending,
          Group: false,
        },
      ],
    },
    HiderSettings: {
      Defaults: [],
    },
    PagerSettings: {
      ResultsPerPage: 25,
    },
  };

  const _reqmeta: string[] = ["PDFs.Concat.LCL>LCL.Output"];

  const _gworplace: GetWorkOrderResponse[] = [];

  const API = useApi();
  const userauth = useUser();
  const [username, setUsername] = useState("");
  const [env, setEnv] = useState(props.Environment);
  const [olderThan, setOlderThan] = useState(undefined);
  const [newerThan, setNewerThan] = useState(undefined);
  const [WORs, setWORs] = useState(_gworplace);
  const [mutatedWORs, setMutatedWORs] = useState(Array<MondrianInputObject>);

  const [Loading, setLoading] = useState(true);

  const refTimeoutProcessing = useRef(null);
  const refTimeoutStale = useRef(null);
  const refTimeoutAll = useRef(null);

  const StatefullyMergeWorkOrderResponse = (GWOR: GetWorkOrderResponse) => {
    setWORs(
      [...WORs]
        .filter((th) => th.WorkOrderID !== GWOR.WorkOrderID)
        .concat(GWOR),
    );
  };

  const StatefullyMergeWorkOrderResponses = (GWORs: GetWorkOrderResponse[]) => {
    setWORs(
      [...WORs]
        .filter(
          (th) => !GWORs.some((ith) => ith.WorkOrderID === th.WorkOrderID),
        )
        .concat(GWORs),
    );
  };

  useEffect(() => {
    setUsername(
      userauth?.ViewAsUser?.userPrincipalName?.replace("@essex.ac.uk", "") ||
        userauth?.AuthedUser?.userPrincipalName?.replace("@essex.ac.uk", ""),
    );
  }, [userauth]);

  const LoadWORs = () => {
    //  console.log("LoadWORs() => ...");
    //  console.log(username);
    //  console.log(env);
    //  console.log(_reqmeta);
    //  console.log(olderThan);
    //  console.log(newerThan);

    API?.Invar?.GetAllWorkOrdersForUser(
      username,
      env,
      _reqmeta,
      undefined,
      olderThan,
      newerThan,
    ).then((data) => {
      //  console.log(data);
      setWORs(data);
    });
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (username !== undefined && username !== "") {
      LoadWORs();
    }
    return () => {
      isMounted = false;
    };
  }, [username, env]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (WORs !== undefined) {
      setMutatedWORs(MutateWORs(WORs));
      RegenerateTimers();
    }
    return () => {
      isMounted = false;
    };
  }, [WORs]);

  const PollWorkOrders = async (
    woids: GetWorkOrderResponse[],
    relay: boolean,
  ) => {
    const _wors: GetWorkOrderResponse[] = [];
    var tasks = [];
    woids.forEach((th) => {
      tasks.push(
        API.Invar.GetWorkOrder(th.WorkOrderID, th.ConveyanceID, _reqmeta).then(
          (data) => {
            _wors.push(data);
          },
        ),
      );
    });
    await Promise.all(tasks);
    StatefullyMergeWorkOrderResponses(_wors);
    if (relay) {
      RegenerateTimers();
    }
  };

  const RegenerateTimers = () => {
    //  console.log("RegenerateTimers");
    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);

    if (refTimeoutProcessing !== undefined) {
      if (refTimeoutProcessing.current !== undefined) {
        clearTimeout(refTimeoutProcessing.current);
        refTimeoutProcessing.current = undefined;
      }
    }

    if (refTimeoutStale !== undefined) {
      if (refTimeoutStale.current !== undefined) {
        clearTimeout(refTimeoutStale.current);
        refTimeoutStale.current = undefined;
      }
    }

    if (refTimeoutAll !== undefined) {
      if (refTimeoutAll.current !== undefined) {
        clearTimeout(refTimeoutAll.current);
        refTimeoutAll.current = undefined;
      }
    }

    var Processings: GetWorkOrderResponse[] = [];
    var Stales: GetWorkOrderResponse[] = [];

    // for each work order marked as Processing, we register a new Interval with JS to refresh that particular Work Order after 15s.
    //  console.log(audits);

    WORs.forEach((th) => {
      if (th.Status === "Processing") {
        //  console.log("RegenerateTimers - proc +1");
        Processings.push(th);
      } else if (th.Status === "Stale") {
        //  console.log("RegenerateTimers - stal +1");
        Stales.push(th);
      }
    });

    //  console.log(Processings);
    if (Processings.length > 0) {
      refTimeoutProcessing.current = setTimeout(async () => {
        PollWorkOrders(Processings, true);
      }, 5000);
    }

    //  console.log(Stales);
    if (Stales.length > 0) {
      refTimeoutStale.current = setTimeout(async () => {
        PollWorkOrders(Stales, false);
      }, 60000);
    }

    if (Processings.length === 0 && Stales.length === 0) {
      refTimeoutAll.current = setTimeout(async () => {
        LoadWORs();
        RegenerateTimers();
      }, 15000);
    }

    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);
  };

  const Retry = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.RefireWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const Cancel = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.CancelWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const MutLookup_StatusCSS: { [id: string]: string } = {
    Completed: "Mondrian_Status Completed",
    Cancelled: "Mondrian_Status Cancelled",
    Processing: "Mondrian_Status Processing",
    Stale: "Mondrian_Status Stale",
    Failed: "Mondrian_Status Failed",
    "Permanently Failed": "Mondrian_Status Failed",
    "": "",
  };

  const MutLookup_StatusLabel: { [id: string]: string } = {
    Completed: "Completed",
    Cancelled: "Cancelled",
    Processing: "Processing",
    Stale: "Stale",
    Failed: "Failed",
    "Permanently Failed": "Failed",
    "": "",
  };

  const MutateWORs = (WORs: GetWorkOrderResponse[]): MondrianInputObject[] => {
    let returner: MondrianInputObject[] = [];

    WORs.sort((lf, rg) => {
      return lf.RequestedDate < rg.RequestedDate
        ? 1
        : lf.RequestedDate === rg.RequestedDate
          ? 0
          : -1;
    }).forEach((th) => {
      returner.push({
        ConveyanceName: th.ConveyanceName,
        RequestedBy: th.RequestedBy,
        InitialValue: th.InitialValue,
        RequestedDate: {
          _TYPE: "Element",
          ElementValue:
            th.RequestedDate === null || th.RequestedDate === undefined ? (
              <></>
            ) : (
              <span>
                {dayjs(th.RequestedDate + "0Z").format("HH:mm:ss DD-MM-YYYY")}
              </span>
            ),
          GroupingValue: dayjs(th.RequestedDate + "0Z").format("YYYY-MM-DD"),
          SortingValue: dayjs(th.RequestedDate + "0Z"),
        },
        FulfilledDate: {
          _TYPE: "Element",
          ElementValue:
            th.FulfilledDate === null || th.FulfilledDate === undefined ? (
              <></>
            ) : (
              <span>
                {dayjs(th.FulfilledDate + "0Z").format("HH:mm:ss DD-MM-YYYY")}
              </span>
            ),
          GroupingValue: dayjs(th.FulfilledDate + "0Z").format("YYYY-MM-DD"),
          SortingValue: dayjs(th.FulfilledDate + "0Z"),
        },
        Status: {
          _TYPE: "String",
          StringValue: MutLookup_StatusLabel[th.Status],
          GroupingValue: MutLookup_StatusLabel[th.Status],
          SortingValue: MutLookup_StatusLabel[th.Status],
          CssClass: MutLookup_StatusCSS[th.Status],
        },
        Action: {
          _TYPE: "Element",
          GroupingValue: th.Status,
          SortingValue: th.Status,
          ElementValue:
            th.Status === "Processing" || th.Status === "Stale" ? (
              <input
                className="Mondrian_Button"
                type="button"
                value="Cancel"
                onClick={() => {
                  Cancel(th.ConveyanceID, th.WorkOrderID);
                }}
              ></input>
            ) : th.Status === "Failed" ? (
              <input
                className="Mondrian_Button"
                type="button"
                value="Retry"
                onClick={() => {
                  Retry(th.ConveyanceID, th.WorkOrderID);
                }}
              ></input>
            ) : th.Status === "Completed" ? (
              <span>
                {th.RelevantMetadata.find(
                  (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Confirmed",
                )?.DatumValue === "false"
                  ? "No documents found."
                  : th.RelevantMetadata.find(
                      (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                    )?.DatumValue}
              </span>
            ) : (
              <></>
            ),
        },
      });
    });

    return returner;
  };

  useEffect(() => {
    if (mutatedWORs !== undefined) {
      setLoading(false);
    }
  }, [mutatedWORs]);

  return (
    <div className="InvarAuditor">
      <div
        className="ControlPanel"
        hidden={
          !userauth?.AuthedUser?.groups.some(
            (th) => th.onPremSamName === "spdev-g",
          )
        }
      >
        <div className="Title">
          <strong>SPDev Control Panel: </strong>
          <button onClick={() => setEnv("LIVE")} disabled={env === "LIVE"}>
            LIVE
          </button>
          <button onClick={() => setEnv("TEST")} disabled={env === "TEST"}>
            TEST
          </button>
          <button onClick={() => setEnv("DEV")} disabled={env === "DEV"}>
            DEV
          </button>
          <button onClick={() => setEnv("LOCAL")} disabled={env === "LOCAL"}>
            LOCAL
          </button>
        </div>
      </div>
      <div className="Summary">
        <h2>Automation History</h2>
      </div>
      <br />
      <Mondrian
        Name="AutomationHistory"
        Caption="This page shows your historic automated jobs. Document uploads, report requests and other things."
        Debug={false}
        Settings={_settings}
        InputObjects={mutatedWORs}
        Columns={columns}
        LoadingFlag={Loading}
      ></Mondrian>
    </div>
  );
}
