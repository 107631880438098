import { Link } from "@fluentui/react";
import { ReactElement, useEffect, useState } from "react";
import { DataAccess } from "../data/DataAccess";
import { FolderFilled } from "@fluentui/react-icons";

interface IProps {
  prid: string;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  da: DataAccess;
}

export function ECFLink(props: IProps) {
  let [hasEcf, setHasEcf] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      let hasEcf = await props.da.hasEcf(props.prid);
      setHasEcf(hasEcf);
    })();
  }, []);

  var link: string = "";
  if (props.Environment === "LOCAL") {
    link =
      "http://localhost:" + import.meta.env.VITE_PORT + "/ECF/" + props.prid;
  } else if (props.Environment === "DEV") {
    link = "https://ecfdev.essex.ac.uk/ECF/" + props.prid;
  } else if (props.Environment === "TEST") {
    link = "https://ecftest.essex.ac.uk/ECF/" + props.prid;
  } else if (props.Environment === "LIVE") {
    link = "https://ecf.essex.ac.uk/ECF/" + props.prid;
  }

  return hasEcf ? (
    props.Environment === "LOCAL" ? (
      <div title="Environment switch not possible on local; change auto-environment in code.">
        <FolderFilled color="red"></FolderFilled> ECF Exists
      </div>
    ) : (
      <a href={link}>
        <FolderFilled color="green"></FolderFilled> ECF Exists
      </a>
    )
  ) : (
    <div>
      <FolderFilled color="gray"></FolderFilled> No ECF
    </div>
  );
}
