import "../assets/css/UCASDAprintreq.css";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess } from "../data/DataAccess";
import { Logging } from "../Logging";
import { ConveyanceResponse } from "../data/InvarDataModels/ConveyanceResponse";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../Providers/ApiProvider";
import { useUser } from "../Providers/UserProvider";
import { GetMergedWorkOrderResponse } from "../data/InvarDataModels/GetMergedWorkOrderResponse";
import dayjs from "dayjs";
import {
  Mondrian,
  MondrianColumn,
  MondrianInputObject,
} from "@spt/mondrian/exports";

interface UCASDAPVProps {
  Logger: Logging;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export default function UCASDAprintreq(props: UCASDAPVProps) {
  const API = useApi();
  // keep, could be useful elsewhere - use to test current page against live data
  //  const Surface = useSurface();
  //  const MSAL = useMsal();
  //  const API = {
  //    Invar: new InvarDataAccess(props.Logger, MSAL.instance, MODE.PRODUCTION),
  //    General: new DataAccess(
  //      props.Logger,
  //      MSAL.instance,
  //      MODE.PRODUCTION,
  //      Surface,
  //    ),
  //  };

  const identity = useUser();
  const ConveyanceName: string = "Fulfil UCAS or DA Print View Request";
  const _reqmeta: string[] = [];

  const [UCASNo, setUCASNo] = useState("");
  const [ASC, setASC] = useState("");
  const [UCASNoInvalid, setUCASNoInvalid] = useState(false);

  const [ThisConveyance, setConveyance] = useState(
    ConveyanceResponse.prototype,
  );

  const [theseWorkOrders, setTheseWorkOrders] = useState(
    Array<GetMergedWorkOrderResponse>,
  );

  const [mutatedWorkOrders, setMutatedWorkOrders] = useState(
    Array<MondrianInputObject>,
  );

  const [Loading, setLoading] = useState(true);

  const refReloadInterval = useRef(undefined);

  const columns: MondrianColumn[] = [
    {
      HumanName: "UCAS / DA Number",
      MachineName: "UCASNo",
      Filterable: true,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "App Scheme Code",
      MachineName: "ASC",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Requested By",
      MachineName: "RequestedBy",
      Filterable: false,
      Sortable: false,
      Groupable: true,
      Hideable: false,
    },
    {
      HumanName: "Requested Date",
      MachineName: "RequestedDate",
      Filterable: false,
      Sortable: true,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Pickup Date",
      MachineName: "PickupDate",
      Filterable: false,
      Sortable: true,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Fulfilled Date",
      MachineName: "FulfilledDate",
      Filterable: false,
      Sortable: true,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Status",
      MachineName: "Status",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "Action",
      MachineName: "Action",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
    {
      HumanName: "_ROW_PROCESSING",
      MachineName: "_ROW_PROCESSING",
      Filterable: false,
      Sortable: false,
      Groupable: false,
      Hideable: false,
    },
  ];

  useEffect(() => {
    API.Invar.GetConveyanceByName(ConveyanceName).then((_c) => {
      setConveyance(_c);
    });
  }, []);

  useEffect(() => {
    if (
      ThisConveyance &&
      ThisConveyance.ConveyanceID !== 0 &&
      ThisConveyance.ConveyanceID !== null &&
      ThisConveyance.ConveyanceID !== undefined
    ) {
      Reload();
      if (refReloadInterval.current === undefined) {
        refReloadInterval.current = setInterval(async () => {
          if (
            theseWorkOrders.some(
              (th) =>
                th.MergedWorkOrder === undefined ||
                th.MergedWorkOrder.FulfilledDate === undefined,
            )
          ) {
            Reload();
          }
        }, 30000);
      }
    }
  }, [ThisConveyance]);

  const Reload = () => {
    const _rec_s: GetMergedWorkOrderResponse[] = [];

    API.Invar.GetMergedWorkOrders(
      props.Environment,
      _reqmeta,
      ThisConveyance.ConveyanceID,
      "invar.__UCASDAPV_Recent",
      [],
      "WOID",
    ).then((data) => {
      setTheseWorkOrders(_rec_s.concat(data));
      setLoading(false);
    });
  };

  useEffect(() => {
    if (theseWorkOrders !== undefined) {
      setMutatedWorkOrders(MutateResponses(theseWorkOrders));
    }
  }, [theseWorkOrders]);

  const Retry = async (CID: number, WOID: number) => {
    API.Invar.RefireWorkOrder(WOID, CID, _reqmeta).then(() => {
      Reload();
    });
  };

  const Cancel = async (CID: number, WOID: number) => {
    API.Invar.CancelWorkOrder(WOID, CID, _reqmeta).then(() => {
      Reload();
    });
  };

  const MutateResponses = (
    reports: GetMergedWorkOrderResponse[],
  ): MondrianInputObject[] => {
    let returner: MondrianInputObject[] = [];

    reports
      .sort((left, right) => {
        const lfd = dayjs(
          left.Fields.find((th) => th.Name === "DateRequested").Value,
        );
        const rfd = dayjs(
          right.Fields.find((th) => th.Name === "DateRequested").Value,
        );

        return rfd.diff(lfd);
      })
      .forEach((th) => {
        const UCASNo = th.Fields.find((th) => th.Name === "UCASNo").Value;
        const ASC = th.Fields.find((th) => th.Name === "AppSchemeCode").Value;
        const RequestedDate = dayjs(
          th.Fields.find((th) => th.Name === "DateRequested").Value,
        );
        const PickupDate =
          th.MergedWorkOrder && th.MergedWorkOrder.RequestedDate
            ? dayjs(th.MergedWorkOrder.RequestedDate)
            : "";
        const CompletedDate =
          th.MergedWorkOrder && th.MergedWorkOrder.FulfilledDate
            ? dayjs(th.MergedWorkOrder.FulfilledDate)
            : "";
        const RequestedBy =
          th.MergedWorkOrder && th.MergedWorkOrder.RequestedBy
            ? th.MergedWorkOrder.RequestedBy
            : "Automated";

        returner.push({
          UCASNo: UCASNo,
          ASC: ASC,
          RequestedBy: RequestedBy.startsWith("Invar")
            ? "Automated"
            : RequestedBy,
          RequestedDate: RequestedDate.toString(),
          PickupDate: PickupDate.toString(),
          FulfilledDate: CompletedDate.toString(),
          Status: {
            _TYPE: "Element",
            ElementValue: (
              <span
                className={
                  th.MergedWorkOrder === undefined ||
                  th.MergedWorkOrder === null
                    ? "Mondrian_Awaiting_Pickup"
                    : th.MergedWorkOrder.Status === "Completed"
                      ? "Mondrian_Status Completed"
                      : th.MergedWorkOrder.Status === "Cancelled"
                        ? "Mondrian_Status Cancelled"
                        : th.MergedWorkOrder.Status === "Processing"
                          ? "Mondrian_Status Processing"
                          : th.MergedWorkOrder.Status === "Stale"
                            ? "Mondrian_Status Stale"
                            : th.MergedWorkOrder.Status === "Failed"
                              ? "Mondrian_Status Failed"
                              : th.MergedWorkOrder.Status ===
                                  "Permanently Failed"
                                ? "Mondrian_Status Failed"
                                : ""
                }
              >
                <span className="Label">
                  {th.MergedWorkOrder === undefined ||
                  th.MergedWorkOrder === null
                    ? "Awaiting Pickup"
                    : th.MergedWorkOrder.Status === "Completed"
                      ? "Completed"
                      : th.MergedWorkOrder.Status === "Cancelled"
                        ? "Cancelled"
                        : th.MergedWorkOrder.Status === "Processing"
                          ? "Processing"
                          : th.MergedWorkOrder.Status === "Stale"
                            ? "Stale"
                            : th.MergedWorkOrder.Status === "Failed"
                              ? "Failed"
                              : th.MergedWorkOrder.Status ===
                                  "Permanently Failed"
                                ? "Failed"
                                : "-"}
                </span>
                {th.MergedWorkOrder !== undefined &&
                th.MergedWorkOrder !== null &&
                th.MergedWorkOrder.Status !== "Completed" ? (
                  <span className="Mondrian_Status_Detail">
                    {th.MergedWorkOrder.SegmentaryStatus.map((seg, k) => {
                      return (
                        <span
                          key={"sv_" + k.toString()}
                          className={
                            "Segment" +
                            (seg.StepAttempts.some(
                              (iseg) => iseg.Completed !== null,
                            )
                              ? " Completed"
                              : seg.StepAttempts.some(
                                    (iseg) => iseg.Failed !== null,
                                  )
                                ? " Failed"
                                : seg.StepAttempts.length === 0 &&
                                    seg.Ordinal === 1
                                  ? " Originator"
                                  : seg.StepAttempts.length === 0 &&
                                      seg.Ordinal !== 1
                                    ? " NotYetReached"
                                    : " InFlight")
                          }
                        >
                          <span className="Ordinal">
                            &nbsp;
                            {
                              //seg.Ordinal.toString()
                            }
                          </span>
                          {
                            //     <div className="Detail">
                            //       <div className="SegmentName">{seg.SegmentName}</div>
                            //       <div className="ActionName">{seg.ActionName}</div>
                            //       <div className="StepAttempts">
                            //         {seg.StepAttempts.map((satt) => {
                            //           return (
                            //             <div className="StepAttempt">{satt.Created}</div>
                            //           );
                            //         })}
                            //       </div>
                            //     </div>
                          }
                        </span>
                      );
                    })}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            GroupingValue: th.MergedWorkOrder.Status,
            SortingValue: th.MergedWorkOrder.Status,
          },
          Jim: {
            _TYPE: "Element",
            ElementValue: !th.MergedWorkOrder ? (
              <span className="Mondrian_Awaiting_Pickup">Awaiting Pickup</span>
            ) : th.MergedWorkOrder.Status === "Completed" ? (
              <span className="Mondrian_Status Completed">Completed</span>
            ) : th.MergedWorkOrder.Status === "Cancelled" ? (
              <span className="Mondrian_Status Cancelled">Cancelled</span>
            ) : th.MergedWorkOrder.Status === "Processing" ? (
              <span className="Mondrian_Status Processing">
                Processing
                {
                  //({th.CurrentStep.toString()}/{th.MaxStep.toString()})
                }
              </span>
            ) : th.MergedWorkOrder.Status === "Stale" ? (
              <span className="Mondrian_Status Stale">
                Stale
                {
                  //({th.CurrentStep.toString()}/{th.MaxStep.toString()})
                }
              </span>
            ) : th.MergedWorkOrder.Status === "Failed" ? (
              <span className="Mondrian_Status Failed">Failed</span>
            ) : th.MergedWorkOrder.Status === "Permanently Failed" ? (
              <span className="Mondrian_Status Failed">Failed</span>
            ) : (
              <>-</>
            ),
            GroupingValue: th.MergedWorkOrder.Status,
            SortingValue: th.MergedWorkOrder.Status,
          },
          Action: {
            _TYPE: "Element",
            ElementValue: !th.MergedWorkOrder ? (
              <span
                className="Mondrian_Advisory"
                title="Workflows will usually be picked up within 5 mins of being
                generated."
              >
                &lt; 5:00
              </span>
            ) : th.MergedWorkOrder.Status === "Processing" ? (
              <input
                className="Mondrian_Button"
                type="button"
                value="Cancel"
                onClick={() => {
                  Cancel(
                    th.MergedWorkOrder.ConveyanceID,
                    th.MergedWorkOrder.WorkOrderID,
                  );
                }}
              ></input>
            ) : th.MergedWorkOrder.Status === "Failed" ? (
              <input
                className="Mondrian_Button"
                type="button"
                value="Retry"
                onClick={() => {
                  Retry(
                    th.MergedWorkOrder.ConveyanceID,
                    th.MergedWorkOrder.WorkOrderID,
                  );
                }}
              ></input>
            ) : th.MergedWorkOrder.Status === "Completed" ? (
              <span>
                <a
                  className="Mondrian_Button"
                  type="button"
                  rel="external"
                  href={"/UGEAF/" + UCASNo}
                  target="_blank"
                  title="(after generation, document may take up to 30 mins to propagate
                through SharePoint)"
                >
                  View Applicant Record
                </a>
              </span>
            ) : (
              <></>
            ),
            GroupingValue: th.MergedWorkOrder.Status,
            SortingValue: th.MergedWorkOrder.Status,
          },
        });
      });

    return returner;
  };

  return (
    <div className="UCASDAprintreq">
      <h2>UCAS & DA Reprint Request</h2>

      <div className="PrintSummary">
        {/* <h2>{ this.props.GetMode(this.props.Mode[1]).Human } Print Requests</h2> */}
        <div className="PrintAgainBar">
          <div>Student Details:</div>

          <label htmlFor="MainUCASDAField" id="MainUCASDALabel">
            UCAS/DA Number
          </label>
          <input
            type="textbox"
            value={UCASNo}
            placeholder={"UCAS/DA Number"}
            onChange={(e) => {
              setUCASNo(e.target.value);
              setUCASNoInvalid(false);
            }}
            id="Main_UCAS_DA_number"
            required={true}
          ></input>

          <label htmlFor="MainAppSchemeCodeField" id="MainAppSchemeCodeLabel">
            App Scheme Code
          </label>

          <input
            type="textbox"
            value={ASC}
            placeholder={"App Scheme Code"}
            onChange={(e) => {
              setASC(e.target.value);
            }}
            id="Main_UCAS_DA_ASC"
            required={true}
          ></input>

          {UCASNoInvalid ? (
            <div className="InvalidIdentifier">
              This identifier is invalid. Please alter and try again.
            </div>
          ) : (
            <></>
          )}

          <button
            className="Submit"
            disabled={
              UCASNo === "" || UCASNo.length < 4 || UCASNoInvalid || ASC === ""
            }
            onClick={async () => {
              const sanitisedUCASNo: string = UCASNo.replaceAll("\r", "")
                .replaceAll("\n", "")
                .replaceAll("\t", "")
                .replaceAll(";", "")
                .replaceAll(",", "")
                .trim();
              const sanitisedASC: string = ASC.replaceAll("\r", "")
                .replaceAll("\n", "")
                .replaceAll("\t", "")
                .replaceAll(";", "")
                .replaceAll(",", "")
                .trim();

              if (
                await API.Invar.ValidateAnyIdent(sanitisedUCASNo, "ucas_no")
              ) {
                setUCASNo("");
                setASC("");

                const matlock =
                  "/uaducasform_" +
                  sanitisedUCASNo +
                  "_" +
                  dayjs().unix().valueOf() +
                  ".pdf";

                const spuploc =
                  "https://essexuniversity.sharepoint.com/sites/FY" +
                  dayjs().year().toString() +
                  "-UGEAF/" +
                  (props.Environment === "DEV" || props.Environment === "TEST"
                    ? "Test/"
                    : "") +
                  "UCASForms/" +
                  sanitisedUCASNo.substring(7, 10) +
                  "/" +
                  sanitisedUCASNo.substring(4, 7) +
                  matlock;

                const loctarg = "//##MACHINE##/Reports/UCASDA" + matlock;

                var ReqID = await API.General.get(
                  "/sproc",
                  JSON.stringify({
                    sproc: "sputilities.invar.__UCASDAPV_New_Request",
                    params: ["@UCASNO", sanitisedUCASNo, "@ASC", sanitisedASC],
                  }),
                );

                API.Invar.Enqueue(
                  identity.AuthedUser.userPrincipalName.replace(
                    "@essex.ac.uk",
                    "",
                  ),
                  ThisConveyance.ConveyanceID,
                  props.Environment,
                  sanitisedUCASNo + "," + sanitisedASC,
                  [
                    { Name: "UCASDA", Value: sanitisedUCASNo },
                    { Name: "ASC", Value: sanitisedASC },
                    { Name: "UPLOADTO", Value: spuploc },
                    { Name: "RENDERTO", Value: loctarg },
                    {
                      Name: "TARGET",
                      Value: sanitisedUCASNo.startsWith("DA")
                        ? "sputilities.invar.__UCASDAPV_DAPrintView"
                        : "sputilities.invar.__UCASDAPV_UCASPrintView",
                    },
                    { Name: "REQUESTID", Value: ReqID[0][0].requestid },
                  ],
                ).then(() => {
                  Reload();
                });
              } else {
                setUCASNoInvalid(true);
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="UCASDAprintreqPanel">
        <h2>My Requests</h2>
        <Mondrian
          Name="MyRequests"
          Caption="The current user's most recently requested UCAS/DA Print Views."
          Debug={false}
          InputObjects={mutatedWorkOrders.filter((th) => {
            return (
              th.RequestedBy ===
              identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", "")
            );
          })}
          Columns={columns}
          LoadingFlag={Loading}
          Settings={{
            FilterSettings: {
              Defaults: [],
            },
            GroupAndSortSettings: {
              ShowSubcount: true,
              Defaults: [],
            },
            HiderSettings: {
              Defaults: [],
            },
            PagerSettings: {
              ResultsPerPage: 25,
            },
          }}
        ></Mondrian>
        <h2>Recent</h2>
        <Mondrian
          Name="Recent"
          Caption="The 100 most recent generated UCAS/DA Print Views that weren't requested by the current user."
          Debug={false}
          InputObjects={mutatedWorkOrders.filter(
            (th) =>
              th.RequestedBy !==
              identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
          )}
          Columns={columns}
          LoadingFlag={Loading}
          Settings={{
            FilterSettings: {
              Defaults: [],
            },
            GroupAndSortSettings: {
              ShowSubcount: true,
              Defaults: [],
            },
          }}
        ></Mondrian>
      </div>
    </div>
  );
}
