import React, { useEffect, useRef, useState } from "react";
import { DataAccess } from "../../../data/DataAccess";
import "../../../assets/css/GDPRSurface.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../../../Logging";
import { useApi } from "../../../Providers/ApiProvider";
import { GetWorkOrderResponse } from "../../../data/InvarDataModels/GetWorkOrderResponse";
import { useUser } from "../../../Providers/UserProvider";
import { ConveyanceResponse } from "../../../data/InvarDataModels/ConveyanceResponse";
import dayjs from "dayjs";
import {
  Mondrian,
  MondrianColumn,
  MondrianInputObject,
} from "@spt/mondrian/exports";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export function GDPRSurface(props: IProps) {
  const ConveyanceName: string = "Fulfil Subject Access Request";
  const [ThisConveyance, setConveyance] = useState(
    ConveyanceResponse.prototype,
  );
  const _reqmeta: string[] = [
    "PDFs.Concat.LCL>LCL.Output",
    "PDFs.Concat.LCL>LCL.Confirmed",
  ];
  const API = useApi();
  const identity = useUser();
  const [ident, setIdent] = useState("");
  const [identInvalid, setIdentInvalid] = useState(false);
  const [reports, setReports] = useState(Array<GetWorkOrderResponse>);
  const [mutatedReports, setMutatedReports] = useState(
    Array<MondrianInputObject>,
  );

  const [Loading, setLoading] = useState(true);

  const refTimeoutProcessing = useRef(null);
  const refTimeoutStale = useRef(null);
  const refTimeoutAll = useRef(null);

  const StatefullyMergeWorkOrderResponse = (GWOR: GetWorkOrderResponse) => {
    setReports(
      [...reports]
        .filter((th) => th.WorkOrderID !== GWOR.WorkOrderID)
        .concat(GWOR),
    );
  };

  const StatefullyMergeWorkOrderResponses = (GWORs: GetWorkOrderResponse[]) => {
    setReports(
      [...reports]
        .filter(
          (th) => !GWORs.some((ith) => ith.WorkOrderID === th.WorkOrderID),
        )
        .concat(GWORs),
    );
  };

  const columns: MondrianColumn[] = [
    {
      HumanName: "Identifier",
      MachineName: "InitialValue",
      Sortable: true,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Requested By",
      MachineName: "RequestedBy",
      Sortable: true,
      Groupable: true,
      Filterable: true,
      Hideable: false,
    },
    {
      HumanName: "Requested Date",
      MachineName: "RequestedDate",
      Sortable: true,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Fulfilled Date",
      MachineName: "FulfilledDate",
      Sortable: true,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Status",
      MachineName: "Status",
      Sortable: false,
      Groupable: true,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Action",
      MachineName: "Action",
      Sortable: false,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "_ROW_PROCESSING",
      MachineName: "_ROW_PROCESSING",
      Sortable: false,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
  ];

  useEffect(() => {
    API.Invar.GetConveyanceByName(ConveyanceName).then((_c) => {
      setConveyance(_c);
    });
  }, []);

  const LoadReports = () => {
    const _reps: GetWorkOrderResponse[] = [
      /*
         {
           WorkOrderID: 1,
           ConveyanceID: 1,
           RequestedBy: "jmcait",
           InitialValue: "CAITS22608",
           RequestedDate: new Date(),
           FulfilledDate: new Date(),
           Status: "Processing",
           RelevantMetadata: [
             { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
           ],
         },
         {
           WorkOrderID: 2,
           ConveyanceID: 1,
           RequestedBy: "jmcait",
           InitialValue: "CAITS22608",
           RequestedDate: new Date(),
           FulfilledDate: new Date(),
           Status: "Failed",
           RelevantMetadata: [
             { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
           ],
         },
         {
           WorkOrderID: 3,
           ConveyanceID: 1,
           RequestedBy: "jmcait",
           InitialValue: "CAITS22608",
           RequestedDate: new Date(),
           FulfilledDate: new Date(),
           Status: "Permanently Failed",
           RelevantMetadata: [
             { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
           ],
         },
         {
           WorkOrderID: 4,
           ConveyanceID: 1,
           RequestedBy: "jmcait",
           InitialValue: "CAITS22608",
           RequestedDate: new Date(),
           FulfilledDate: new Date(),
           Status: "Cancelled",
           RelevantMetadata: [
             { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
           ],
         },
         {
           WorkOrderID: 5,
           ConveyanceID: 1,
           RequestedBy: "jmcait",
           InitialValue: "CAITS22608",
           RequestedDate: new Date(),
           FulfilledDate: new Date(),
           Status: "Completed",
           RelevantMetadata: [
             { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
           ],
         },
         */
    ];

    API.Invar.GetAllWorkOrdersForUser(
      identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
      props.Environment,
      _reqmeta,
      ThisConveyance.ConveyanceID,
      undefined,
      undefined,
    ).then((data) => {
      setReports(_reps.concat(data));
      setLoading(false);
    });
  };

  useEffect(() => {
    if (
      ThisConveyance &&
      ThisConveyance.ConveyanceID !== 0 &&
      ThisConveyance.ConveyanceID !== null &&
      ThisConveyance.ConveyanceID !== undefined
    ) {
      LoadReports();
    }
  }, [ThisConveyance]);

  useEffect(() => {
    let isMounted = true;
    if (reports !== undefined) {
      setMutatedReports(MutateReports(reports));
      RegenerateTimers();
    }
    return () => {
      isMounted = false;
    };
  }, [reports]);

  const PollWorkOrders = async (
    woids: GetWorkOrderResponse[],
    relay: boolean,
  ) => {
    const _wors: GetWorkOrderResponse[] = [];
    var tasks = [];
    woids.forEach((th) => {
      tasks.push(
        API.Invar.GetWorkOrder(th.WorkOrderID, th.ConveyanceID, _reqmeta).then(
          (data) => {
            _wors.push(data);
          },
        ),
      );
    });
    await Promise.all(tasks);
    StatefullyMergeWorkOrderResponses(_wors);
    if (relay) {
      RegenerateTimers();
    }
  };

  const RegenerateTimers = () => {
    //  console.log("RegenerateTimers");
    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);

    if (refTimeoutProcessing !== undefined) {
      if (refTimeoutProcessing.current !== undefined) {
        clearTimeout(refTimeoutProcessing.current);
        refTimeoutProcessing.current = undefined;
      }
    }

    if (refTimeoutStale !== undefined) {
      if (refTimeoutStale.current !== undefined) {
        clearTimeout(refTimeoutStale.current);
        refTimeoutStale.current = undefined;
      }
    }

    if (refTimeoutAll !== undefined) {
      if (refTimeoutAll.current !== undefined) {
        clearTimeout(refTimeoutAll.current);
        refTimeoutAll.current = undefined;
      }
    }

    var Processings: GetWorkOrderResponse[] = [];
    var Stales: GetWorkOrderResponse[] = [];

    // for each work order marked as Processing, we register a new Interval with JS to refresh that particular Work Order after 15s.
    //  console.log(audits);

    reports.forEach((th) => {
      if (th.Status === "Processing") {
        //  console.log("RegenerateTimers - proc +1");
        Processings.push(th);
      } else if (th.Status === "Stale") {
        //  console.log("RegenerateTimers - stal +1");
        Stales.push(th);
      }
    });

    //  console.log(Processings);
    if (Processings.length > 0) {
      refTimeoutProcessing.current = setTimeout(async () => {
        PollWorkOrders(Processings, true);
      }, 5000);
    }

    //  console.log(Stales);
    if (Stales.length > 0) {
      refTimeoutStale.current = setTimeout(async () => {
        PollWorkOrders(Stales, false);
      }, 60000);
    }

    if (Processings.length === 0 && Stales.length === 0) {
      refTimeoutAll.current = setTimeout(async () => {
        LoadReports();
        RegenerateTimers();
      }, 15000);
    }

    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);
  };

  const Retry = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.RefireWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const Cancel = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.CancelWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const MutateReports = (
    reports: GetWorkOrderResponse[],
  ): MondrianInputObject[] => {
    let returner: MondrianInputObject[] = [];

    reports
      .sort((lf, rg) => {
        return lf.RequestedDate < rg.RequestedDate
          ? 1
          : lf.RequestedDate === rg.RequestedDate
            ? 0
            : -1;
      })
      .forEach((th) => {
        returner.push({
          InitialValue: th.InitialValue,
          RequestedBy: th.RequestedBy,
          RequestedDate: {
            _TYPE: "String",
            StringValue:
              th.RequestedDate === null || th.RequestedDate === undefined
                ? ""
                : dayjs(th.RequestedDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
            SortingValue: dayjs(th.RequestedDate + "0Z"),
          },
          FulfilledDate: {
            _TYPE: "String",
            StringValue:
              th.FulfilledDate === null || th.FulfilledDate === undefined
                ? ""
                : dayjs(th.FulfilledDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
            SortingValue: dayjs(th.FulfilledDate + "0Z"),
          },
          Status: {
            _TYPE: "Element",
            ElementValue: (
              <span
                className={
                  th.Status === "Completed"
                    ? "Mondrian_Status Completed"
                    : th.Status === "Cancelled"
                      ? "Mondrian_Status Cancelled"
                      : th.Status === "Processing"
                        ? "Mondrian_Status Processing"
                        : th.Status === "Stale"
                          ? "Mondrian_Status Stale"
                          : th.Status === "Failed"
                            ? "Mondrian_Status Failed"
                            : th.Status === "Permanently Failed"
                              ? "Mondrian_Status Failed"
                              : ""
                }
              >
                <span className="Label">
                  {th.Status === "Completed"
                    ? "Completed"
                    : th.Status === "Cancelled"
                      ? "Cancelled"
                      : th.Status === "Processing"
                        ? "Processing"
                        : th.Status === "Stale"
                          ? "Stale"
                          : th.Status === "Failed"
                            ? "Failed"
                            : th.Status === "Permanently Failed"
                              ? "Failed"
                              : "-"}
                </span>
                {
                  ""
                  /*
                th.Status !== "Completed" &&th.Status !== "Failed" &&th.Status !== "Cancelled" ? (
                <span className="Mondrian_Status_Detail">
                  {th.SegmentaryStatus.map((seg, k) => {
                    return (
                      <span
                        key={"sv_" + k.toString()}
                        className={
                          "Segment" +
                          (seg.StepAttempts.some(
                            (iseg) => iseg.Completed !== null,
                          )
                            ? " Completed"
                            : seg.StepAttempts.some(
                                  (iseg) => iseg.Failed !== null,
                                )
                              ? " Failed"
                              : seg.StepAttempts.length === 0 &&
                                  seg.Ordinal === 1
                                ? " Originator"
                                : seg.StepAttempts.length === 0 &&
                                    seg.Ordinal !== 1
                                  ? " NotYetReached"
                                  : " InFlight")
                        }
                      >
                        <span className="Ordinal">
                          &nbsp;
                          {
                            //seg.Ordinal.toString()
                          }
                        </span>
                        {
                          //     <div className="Detail">
                          //       <div className="SegmentName">{seg.SegmentName}</div>
                          //       <div className="ActionName">{seg.ActionName}</div>
                          //       <div className="StepAttempts">
                          //         {seg.StepAttempts.map((satt) => {
                          //           return (
                          //             <div className="StepAttempt">{satt.Created}</div>
                          //           );
                          //         })}
                          //       </div>
                          //     </div>
                        }
                      </span>
                    );
                  })}
                </span>
              ) : (
                ""
              )*/
                }
              </span>
            ),
            GroupingValue: th.Status,
            SortingValue: th.Status,
          },
          Action: {
            _TYPE: "Element",
            ElementValue:
              th.Status === "Processing" || th.Status === "Stale" ? (
                <input
                  className="Mondrian_Button"
                  type="button"
                  value="Cancel"
                  onClick={() => {
                    Cancel(th.ConveyanceID, th.WorkOrderID);
                  }}
                ></input>
              ) : th.Status === "Failed" ? (
                <input
                  className="Mondrian_Button"
                  type="button"
                  value="Retry"
                  onClick={() => {
                    Retry(th.ConveyanceID, th.WorkOrderID);
                  }}
                ></input>
              ) : th.Status === "Completed" ? (
                <span>
                  {th.RelevantMetadata.find(
                    (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Confirmed",
                  )?.DatumValue === "false"
                    ? "No documents found."
                    : th.RelevantMetadata.find(
                        (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                      )?.DatumValue}
                  {
                    //  <a
                    //    className="Mondrian_Button"
                    //    type="button"
                    //    rel="external"
                    //    href={
                    //      "file:" +
                    //      th.RelevantMetadata.find(
                    //        (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                    //      )?.DatumValue.replaceAll("/", "\\")
                    //    }
                    //    target="_blank"
                    //  >
                    //    Download
                    //  </a>
                  }
                </span>
              ) : (
                <></>
              ),
            GroupingValue: th.Status,
            SortingValue: th.Status,
          },
        });

        return returner;
      });

    return returner;
  };

  async function AttemptSubmit() {
    const sanitisedIdent: string = ident
      .replaceAll("\r", "")
      .replaceAll("\n", "")
      .replaceAll("\t", "")
      .replaceAll(";", "")
      .replaceAll(",", "")
      .trim();

    if (
      await API.Invar.ValidateAnyIdent(
        sanitisedIdent,
        "prid,email,asr_key,ucas_no,pg_no,oad_no,hesa_no,clearing_no,cas_no",
      )
    ) {
      setIdent("");

      StatefullyMergeWorkOrderResponse(
        await API.Invar.Enqueue(
          identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
          ThisConveyance.ConveyanceID,
          props.Environment,
          sanitisedIdent,
          [],
        ),
      );
    } else {
      setIdentInvalid(true);
    }
  }

  return (
    <div className="GDPRSurface">
      <h2>Subject Access Request</h2>
      <div className="Description">
        To request a report:
        <ul>
          <li>
            Into the Identifiers field, put the student ID on which you wish to
            report. Most identifiers are supported:
            <ul className="ViableIdents">
              <li>PRID</li>
              <li>Login</li>
              <li>ASR Key</li>
              <li>UCAS No.</li>
              <li>DA No.</li>
              <li>PG No.</li>
              <li>OAD No.</li>
              <li>HESA No.</li>
              <li>Clearing Ref.</li>
              <li>CAS No.</li>
            </ul>
          </li>
          <li>Click Submit.</li>
        </ul>
      </div>
      <label htmlFor="IdentBox">Report Identifiers</label>
      <input
        id="IdentBox"
        type="text"
        value={ident}
        onChange={(e) => {
          setIdent(e.target.value);
          setIdentInvalid(false);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            AttemptSubmit();
          }
        }}
        required={true}
      ></input>
      {ident.includes("@essex.ac.uk") ? (
        <div className="InvalidIdentifier">
          Email addresses don't work here - please try the reportee's login, or
          another identifier.
        </div>
      ) : (
        <></>
      )}
      {identInvalid ? (
        <div className="InvalidIdentifier">
          This identifier is invalid. Please alter and try again.
        </div>
      ) : (
        <></>
      )}
      <button
        className="Submit"
        disabled={
          ident === "" ||
          ident.length < 4 ||
          identInvalid ||
          ident.includes("@essex.ac.uk")
        }
        onClick={async (e) => {
          AttemptSubmit();
        }}
      >
        Submit
      </button>
      <Mondrian
        Name="GDPRGrid"
        Caption="Recently carried out Subject Access Requests, with links to fulfilled reports and options for ongoing jobs."
        Debug={false}
        InputObjects={mutatedReports}
        Columns={columns}
        LoadingFlag={Loading}
        Settings={{
          GroupAndSortSettings: {
            ShowSubcount: true,
            Defaults: [],
          },
          PagerSettings: {
            ResultsPerPage: 25,
          },
        }}
      ></Mondrian>
    </div>
  );
}
