import { Button } from "@fluentui/react-components";
import { SearchRegular, ArrowUpRightFilled } from "@fluentui/react-icons";
import { User } from "../../data/DataAccess";
import IfPermitted from "../IfPermitted";
import { NavigateFunction } from "react-router-dom";
import dayjs from "dayjs";
import { MenuVisibilities } from "../MainBracketWrapper";

export type QuickSearchCharmProps = {
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  AuthedUser: User;
  ViewAsUser: User;
  MenuVisibilities: MenuVisibilities;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
  _miniSearchTerm: string;
  _setMiniSearchTerm: (dave: string) => void;
  Navigation: NavigateFunction;

  Title: string;
  OuterWhitelist: string[];
  InnerWhitelist: string[];
  TextSearchURL: string;
  DateSearchURL?: string;
};

const DateRegexESIS = /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{4}$/;
const DateRegexHyphen = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
const DateRegexSlash = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;

export function QuickSearchCharm(props: QuickSearchCharmProps) {
  const GetValidDOB = (term: string): dayjs.Dayjs | undefined => {
    if (DateRegexSlash.test(term)) {
      // dayjs constructor has parameters for format and locale,
      // but either doesn't respect them or has an undocumented gotcha
      // that I'm sure exists on the other end of two days' googling
      // or something equally insufferable

      //  let ret = dayjs();
      //  let splits = term.split("/").map((th) => Number.parseInt(th));
      //  ret = ret
      //    .set("date", splits[0])
      //    .set("month", splits[1] - 1) // good old zero-indexed months
      //    .set("year", splits[2]);
      //  if (ret.isValid()) {
      //    return ret;
      //  }

      // just so; the parameters don't work at all unless you import a
      // specific extra sublibrary. the method does not tell you they don't work.
      // it just blithely goes on about its day as though it respected all your
      // inputs. this industry is driving me to insanity.

      if (dayjs(term, "DD/MM/YYYY", true).isValid()) {
        return dayjs(term, "DD/MM/YYYY", true);
      }
    }

    if (DateRegexHyphen.test(term)) {
      if (dayjs(term, "DD-MM-YYYY", true).isValid()) {
        return dayjs(term, "DD-MM-YYYY", true);
      }
    }

    if (DateRegexESIS.test(term)) {
      // haha, this one doesn't work in strict! Very odd.
      if (dayjs(term).isValid()) {
        return dayjs(term);
      }
    }

    return undefined;
  };

  return (
    <IfPermitted Whitelist={props.OuterWhitelist}>
      <div
        className="MiniSearchMenu"
        onMouseEnter={() => {
          props.SetMenuVisibility("MiniSearch", true);
        }}
      >
        <div tabIndex={0} className="MiniSearchMenuCharm">
          <SearchRegular></SearchRegular>
        </div>
        <div
          className={
            "MiniSearchMenuReveal" +
            (props.MenuVisibilities.MiniSearch ||
            props.MenuVisibilities.MiniSearchText
              ? " Visible"
              : "")
          }
          aria-expanded={
            props.MenuVisibilities.MiniSearch ||
            props.MenuVisibilities.MiniSearchText
          }
        >
          <div className="Container">
            <div className="MenuTitle">{props.Title}</div>
            <div className="MenuItem Inert MiniSearchInputRack">
              <input
                type="textbox"
                autoComplete="off"
                id="MiniSearchInput"
                value={props._miniSearchTerm}
                onFocus={() => {
                  props.SetMenuVisibility("MiniSearchText", true);
                }}
                onBlur={() => {
                  props.SetMenuVisibility("MiniSearchText", false);
                }}
                onChange={(val) => {
                  props._setMiniSearchTerm(val.target.value);
                }}
                onPaste={(e) => {
                  const sterm = e.clipboardData.getData("text").trim();

                  if (DateRegexESIS.test(sterm)) {
                    const dterm = this.GetValidDOB(sterm);
                    if (dterm !== undefined) {
                      // Only accept values in format 01/JAN/2021 or 01-01-1999
                      this.props.Navigation(
                        props.DateSearchURL + dterm.format("DD-MM-YYYY"),
                      );
                    }
                  }
                }}
                onKeyUp={(ev) => {
                  if (ev.key === "Enter") {
                    const sterm = props._miniSearchTerm
                      .trim()
                      .replaceAll(" ", "_");

                    props._setMiniSearchTerm("");

                    props.SetMenuVisibility("MiniSearch", false);

                    const dterm = GetValidDOB(sterm);

                    //  console.log(props.DateSearchURL);
                    //  console.log(dterm);

                    if (
                      props.DateSearchURL !== undefined &&
                      dterm !== undefined
                    ) {
                      props.Navigation(
                        props.DateSearchURL + dterm.format("DD-MM-YYYY"),
                      );
                    } else {
                      props.Navigation(props.TextSearchURL + sterm);
                    }
                  }
                }}
              ></input>
            </div>
            <div className="MenuItem Inert MiniSearchInputRack">
              <Button
                onClick={() => {
                  const sterm = props._miniSearchTerm
                    .trim()
                    .replaceAll(" ", "_");
                  props._setMiniSearchTerm("");
                  props.SetMenuVisibility("MiniSearch", false);
                  const dterm = GetValidDOB(sterm);
                  if (dterm !== undefined) {
                    props.Navigation(
                      props.DateSearchURL + dterm.format("DD-MM-YYYY"),
                    );
                  } else {
                    props.Navigation(props.TextSearchURL + sterm);
                  }
                }}
              >
                <label htmlFor="MiniSearchInput">Search</label>
              </Button>
              <Button
                onClick={() => {
                  props._setMiniSearchTerm("");
                }}
              >
                Clear
              </Button>
            </div>
            <IfPermitted Whitelist={props.InnerWhitelist}>
              <div className="MenuItem ExternalLink">
                <a
                  href="https://essexuniversity.sharepoint.com/sites/EDRM/SitePages/Search.aspx"
                  target="_blank"
                >
                  Go to EDRM Hub Search{" "}
                  <ArrowUpRightFilled></ArrowUpRightFilled>
                </a>
              </div>
            </IfPermitted>
          </div>
        </div>
      </div>
    </IfPermitted>
  );
}
