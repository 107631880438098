import { SURFACE, useSurface } from "../../Providers/SurfaceProvider";
import React from "react";
import { DB_UserResponse } from "../../models/DB_UserResponse";
import { Button } from "@fluentui/react-components";
import { InvarMicroStatus } from "../Invar/InvarMicroStatus";
import { User } from "../../data/DataAccess";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { IfSurface } from "../IfSurface";
import IfPermitted from "../IfPermitted";
import { StyledDropdown } from "../../UI/StyledDropdown";
import { MenuVisibilities } from "../MainBracketWrapper";

export type UserCharmProps = {
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  AuthedUser: User;
  ViewAsUser: User;
  Header_AuthedCharmData?: DB_UserResponse;
  Header_ViewAsCharmData?: DB_UserResponse;
  ViewAsNotFound: boolean;
  ViewAsAll: boolean;

  IDA: InvarDataAccess;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;

  MenuVisibilities: MenuVisibilities;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
  SetImpersonation: (alias?: string) => void;

  _alias: string;
  _setAlias: (dave: string) => void;

  SetThemeName: (input: string) => void;
};

export function UserCharm(props: UserCharmProps) {
  const Surface = useSurface();

  let currentTheme = localStorage.getItem("Theme");
  let selectedThemeIndex = 0;

  switch (currentTheme) {
    case undefined:
    case "System":
      selectedThemeIndex = 0;
      break;
    case "LightMode":
      selectedThemeIndex = 1;
      break;
    case "DarkMode":
      selectedThemeIndex = 2;
      break;
    case "HiVis DarkMode":
      switch (
        getComputedStyle(document.documentElement).getPropertyValue(
          "--hivis-accent",
        )
      ) {
        case "#ff0":
          selectedThemeIndex = 3;
          break;
        case "#9cff05":
          selectedThemeIndex = 4;
          break;
        case "#ff10f0":
          selectedThemeIndex = 5;
          break;
      }
      break;
    default:
      selectedThemeIndex = 0;
      break;
  }

  return (
    <div
      onMouseEnter={() => {
        props.SetMenuVisibility("User", true);
      }}
      className={
        "UserMenu" +
        (!props.Permission(Surface.CSSClass || "", "ViewAs", true) &&
        !props.Permission(Surface.CSSClass || "", "Impersonate", true) &&
        props._alias !== undefined &&
        props._alias !== ""
          ? " Alert"
          : "")
      }
    >
      <div tabIndex={0} className="UserCharm">
        {props.Header_AuthedCharmData?.Initials}
        {(props.Permission(Surface.CSSClass || "", "ViewAs", true) ||
          props.Permission(Surface.CSSClass || "", "Impersonate", true)) &&
        props.Header_ViewAsCharmData !== undefined &&
        props.Header_ViewAsCharmData.Initials !== "" ? (
          Surface.SURFACE === SURFACE.AOS ? (
            <div className="AOSViewingAsUserCharm">
              {props.Header_ViewAsCharmData?.Login}
            </div>
          ) : (
            <div className="ViewingAsUserCharm">
              {props.Header_ViewAsCharmData?.Initials}
            </div>
          )
        ) : (
          ""
        )}
      </div>
      {props.Header_AuthedCharmData !== undefined ? (
        <div
          className={
            "UserMenuReveal" + (props.MenuVisibilities.User ? " Visible" : "")
          }
          aria-expanded={props.MenuVisibilities.User}
        >
          <div className="Container">
            {props !== undefined ? (
              <div className="MenuItem Inert Title Righter">
                Logged in as {props.Header_AuthedCharmData?.FullName}
              </div>
            ) : (
              <></>
            )}
            {props !== undefined ? (
              <div className="MenuItem Inert">
                <div className="TaskListGroup">Appearance</div>
                <StyledDropdown
                  defaultSelectedIndex={selectedThemeIndex}
                  Options={[
                    {
                      Name: "Automatic (System Preference)",
                      ClassName: "System",
                      Action: () => {
                        props.SetThemeName("System");
                        localStorage.setItem("Theme", "System");
                        localStorage.setItem("HiVisColour", null);
                      },
                    },
                    {
                      Name: "Light Mode",
                      ClassName: "LightMode",
                      Action: () => {
                        props.SetThemeName("LightMode");
                        localStorage.setItem("Theme", "LightMode");
                        localStorage.setItem("HiVisColour", null);
                      },
                    },
                    {
                      Name: "Dark Mode",
                      ClassName: "DarkMode",
                      Action: () => {
                        props.SetThemeName("DarkMode");
                        localStorage.setItem("Theme", "DarkMode");
                        localStorage.setItem("HiVisColour", null);
                      },
                    },
                    {
                      Name: "High Visibility Yellow",
                      ClassName: "HiVisY",
                      Action: () => {
                        props.SetThemeName("HiVis DarkMode");
                        localStorage.setItem("Theme", "HiVis DarkMode");
                        localStorage.setItem("HiVisColour", "#ff0");
                        getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue("--hivis-accent");
                        document.documentElement.style.setProperty(
                          "--hivis-accent",
                          "#ff0",
                        );
                      },
                    },
                    {
                      Name: "High Visibility Green",
                      ClassName: "HiVisG",
                      Action: () => {
                        props.SetThemeName("HiVis DarkMode");
                        localStorage.setItem("Theme", "HiVis DarkMode");
                        localStorage.setItem("HiVisColour", "#9cff05");
                        getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue("--hivis-accent");
                        document.documentElement.style.setProperty(
                          "--hivis-accent",
                          "#9cff05",
                        );
                      },
                    },
                    {
                      Name: "High Visibility Pink",
                      ClassName: "HiVisP",
                      Action: () => {
                        props.SetThemeName("HiVis DarkMode");
                        localStorage.setItem("Theme", "HiVis DarkMode");
                        localStorage.setItem("HiVisColour", "#ff10f0");
                        getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue("--hivis-accent");
                        document.documentElement.style.setProperty(
                          "--hivis-accent",
                          "#ff10f0",
                        );
                      },
                    },
                  ]}
                ></StyledDropdown>
              </div>
            ) : (
              <></>
            )}
            {props.Permission(Surface.CSSClass || "", "ViewAs", true) ||
            props.Permission(Surface.CSSClass || "", "Impersonate", true) ? (
              <React.Fragment>
                {/* // <IfPermitted
          //   Whitelist={[
          //     // Surface.CSSClass.replace("2", "").replace("_LF", "") + ".ViewAs",
          //     // Surface.CSSClass.replace("2", "").replace("_LF", "") +
          //     //   ".Impersonate",
          //     "AOS.ViewAs",
          //     "AOS.Impersonate",
          //   ]}
          // >
         */}

                {props.ViewAsNotFound === true ? (
                  <div className="MenuItem Inert ViewAsTitle Righter">
                    Username not found
                  </div>
                ) : props.ViewAsAll ? (
                  <>
                    <div className="MenuItem Inert ViewAsTitle Righter">
                      Viewing as --ALL--.
                    </div>
                    <div className="MenuItem Inert Breaker Righter Explanatory">
                      (View As --ALL-- overrides all data displays that would
                      usually be restricted to the currently logged in user. For
                      example, the My Tasks page will show tasks for all users
                      when View As --ALL-- is active. )
                    </div>
                  </>
                ) : props.Header_ViewAsCharmData === undefined ||
                  props.Header_ViewAsCharmData?.Initials === "" ? (
                  <div className="MenuItem Inert ViewAsTitle Righter">
                    View As Inactive
                  </div>
                ) : (
                  // ) : Surface.CSSClass ||
                  //   "".replace("2", "").replace("_LF", "") === "AOS" ? (
                  //   <div className="MenuItem Inert ViewAsTitle Righter">
                  //     Viewing As {props.Header_ViewAsCharmData.FullName}
                  //   </div>
                  <div className="MenuItem Inert ViewAsTitle Righter">
                    <p>Viewing As</p>{" "}
                    <p>{props.Header_ViewAsCharmData.FullName}</p>
                  </div>
                )}
                {/* // </IfPermitted> */}

                <div className="MenuItem Inert Righter ViewAsInputRack">
                  <label htmlFor="ViewAsInput">View As:</label>
                  <input
                    type="textbox"
                    autoComplete="off"
                    id="ViewAsInput"
                    value={props._alias}
                    onChange={(val) => {
                      props._setAlias(val.target.value);
                    }}
                    onKeyUp={(ev) => {
                      if (ev.key === "Enter") {
                        props.SetImpersonation(props._alias);
                      }
                    }}
                  ></input>
                  <br />
                  <Button
                    onClick={() => {
                      props.SetImpersonation(props._alias);
                    }}
                  >
                    View As
                  </Button>
                  <Button
                    onClick={() => {
                      props._setAlias("");
                      props.SetImpersonation(undefined);
                      if (Surface.CSSClass == "AOS2") {
                        window.location.assign(window.location.origin);
                      }
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className="MenuItem Inert Righter Explanatory">
                  (please note: View As only allows you to see what another user
                  sees - any edits made to records will still be made under your
                  name)
                </div>
              </React.Fragment>
            ) : props._alias != undefined && props._alias != "" ? (
              <React.Fragment>
                <div className="MenuItem Alert">
                  Please note: you do not have permission to View As and as such
                  the current url impersonation token is being ignored. To stop
                  seeing this message, click the Clear button.
                  <Button
                    onClick={() => {
                      props._setAlias("");
                      props.SetImpersonation(undefined);
                      if (Surface.CSSClass == "AOS2") {
                        window.location.assign(window.location.origin);
                      }
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <IfSurface HideOn={[SURFACE.AOS, SURFACE.EAF]}>
              <div className="MenuItem InternalLink TopDots">
                <IfPermitted Whitelist={["Collagen.Admins", "Admin.Invar"]}>
                  <InvarMicroStatus
                    IDA={props.IDA}
                    Environment={props.Environment}
                  ></InvarMicroStatus>
                </IfPermitted>
              </div>
            </IfSurface>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
