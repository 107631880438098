import { useEffect, useState } from "react";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { BrokerageResponse } from "../../data/InvarDataModels/BrokerageResponse";
import { ConveyanceResponse } from "../../data/InvarDataModels/ConveyanceResponse";
import {
  Mondrian,
  MondrianColumn,
  MondrianInputObject,
} from "@spt/mondrian/exports";
import { Display } from "@fluentui/react-components";

interface IProps {
  IDA: InvarDataAccess;
}

type StructuredConveyance = {
  ConveyanceID: number;
  Name: string;
  View: JSX.Element;
  ActiveAction: JSX.Element;
  Machines: JSX.Element;
};

export const InvarTopology = (props: IProps) => {
  const IDA = props.IDA;

  const [TheseBrokerages, setTheseBrokerages] = useState(
    Array<BrokerageResponse>,
  );
  const [BrokeragesLoading, setBrokeragesLoading] = useState(true);

  const [TheseConveyances, setTheseConveyances] = useState(
    Array<ConveyanceResponse>,
  );
  const [ConveyancesLoading, setConveyancesLoading] = useState(true);

  const [StructuredConveyances, setStructuredConveyances] = useState(
    Array<MondrianInputObject>,
  );

  useEffect(() => {
    if (IDA !== undefined) {
      //  console.log(TheseConveyances);
      if (ConveyancesLoading) {
        IDA.GetConveyances().then((data) => {
          setTheseConveyances(data);
          setConveyancesLoading(false);
        });
      }
      if (BrokeragesLoading) {
        IDA.GetBrokerages().then((data) => {
          setTheseBrokerages(data);
          setBrokeragesLoading(false);
        });
      }
    }
  }, [IDA]);

  const MondrianColumns: MondrianColumn[] = [
    {
      HumanName: "ID",
      MachineName: "ConveyanceID",
      Sortable: false,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Name",
      MachineName: "Name",
      Sortable: true,
      Groupable: false,
      Filterable: true,
      Hideable: false,
    },
    {
      HumanName: "View",
      MachineName: "View",
      Sortable: false,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Status",
      MachineName: "ActiveAction",
      Sortable: false,
      Groupable: true,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "Machines",
      MachineName: "Machines",
      Sortable: false,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
    {
      HumanName: "_ROW_PROCESSING",
      MachineName: "_ROW_PROCESSING",
      Sortable: false,
      Groupable: false,
      Filterable: false,
      Hideable: false,
    },
  ];

  const [_CreateConveyanceName, setCreateConveyanceName] = useState("");

  useEffect(() => {
    if (TheseConveyances.length > 0 && TheseBrokerages.length > 0) {
      const StructuredConveyances: MondrianInputObject[] = [];

      TheseConveyances.sort((l, r) => {
        return l.ConveyanceID - r.ConveyanceID;
      }).forEach((conveyance, i) => {
        const Machines = (
          <div key={"conv_machines_" + i.toString()} className="MultiMachine">
            {TheseBrokerages.filter(
              (brokerage) => brokerage.ConveyanceID === conveyance.ConveyanceID,
            )
              .sort((l, r) => {
                return l.MachineName.localeCompare(r.MachineName);
              })
              .map((brokerage, j) => {
                return (
                  <div
                    key={"Brokerage_" + j.toString()}
                    className="SingleMachine"
                  >
                    <span className="Environment">{brokerage.Environment}</span>
                    <span className="MachName">{brokerage.MachineName}</span>
                    {brokerage.Active ? (
                      <span
                        className="Mondrian_Button Split"
                        onClick={() => {
                          IDA.DisableBrokerage(brokerage.BrokerageID).then(
                            (res) => {
                              setTheseBrokerages(
                                [...TheseBrokerages]
                                  .filter(
                                    (th) => th.BrokerageID !== res.BrokerageID,
                                  )
                                  .concat(res),
                              );
                            },
                          );
                        }}
                      >
                        <span className="Mondrian__Check_True">✔</span>
                        <span>Disable</span>
                      </span>
                    ) : (
                      <span
                        className="Mondrian_Button Split"
                        onClick={() => {
                          IDA.EnableBrokerage(brokerage.BrokerageID).then(
                            (res) => {
                              setTheseBrokerages(
                                [...TheseBrokerages]
                                  .filter(
                                    (th) => th.BrokerageID !== res.BrokerageID,
                                  )
                                  .concat(res),
                              );
                            },
                          );
                        }}
                      >
                        <span className="Mondrian__Check_False Bump">✘</span>
                        <span>Enable</span>
                      </span>
                    )}
                  </div>
                );
              })}
          </div>
        );

        StructuredConveyances.push({
          ConveyanceID: conveyance.ConveyanceID.toString(),
          View: {
            _TYPE: "Element",
            ElementValue: (
              <a
                className="Mondrian_Button Split"
                href={
                  "/AdminPortal/Invar/Conveyance/" +
                  conveyance.ConveyanceID.toString()
                }
                target="_blank"
              >
                <span className="Mondrian__Icon_Neutral Bump">⇗</span>
                <span>View Structure</span>
              </a>
            ),
            SortingValue: conveyance.ConveyanceID.toString(),
            GroupingValue: conveyance.ConveyanceID.toString(),
          },
          Name: conveyance.Name,
          ActiveAction: {
            _TYPE: "Element",
            SortingValue: conveyance.Active.toString(),
            GroupingValue: conveyance.Active ? "Enabled" : "Disabled",
            ElementValue: conveyance.Active ? (
              <span
                className="Mondrian_Button Split"
                onClick={() => {
                  IDA.DisableConveyance(conveyance.ConveyanceID).then((res) => {
                    setTheseConveyances(
                      [...TheseConveyances]
                        .filter((th) => th.ConveyanceID !== res.ConveyanceID)
                        .concat(res),
                    );
                  });
                }}
              >
                <span className="Mondrian__Check_True">✔</span>
                <span>Disable</span>
              </span>
            ) : (
              <span
                className="Mondrian_Button Split"
                onClick={() => {
                  IDA.EnableConveyance(conveyance.ConveyanceID).then((res) => {
                    setTheseConveyances(
                      [...TheseConveyances]
                        .filter((th) => th.ConveyanceID !== res.ConveyanceID)
                        .concat(res),
                    );
                  });
                }}
              >
                <span className="Mondrian__Check_False Bump">✘</span>
                <span>Enable</span>
              </span>
            ),
          },
          Machines: {
            _TYPE: "Element",
            ElementValue: Machines,
            SortingValue: "",
            GroupingValue: "",
          },
        });
      });

      setStructuredConveyances(StructuredConveyances);
    }
  }, [TheseConveyances, TheseBrokerages]);

  return (
    <div className="InvarTopologySurface">
      <h2>Invar Topology</h2>
      <Mondrian
        Name="InvarTopology"
        Caption="Current Topology of all Invar Conveyances."
        Debug={false}
        InputObjects={StructuredConveyances}
        LoadingFlag={ConveyancesLoading || BrokeragesLoading}
        Settings={{ DisplaySettings: { DisplayColumnHeaders: false } }}
        Columns={MondrianColumns}
      ></Mondrian>
    </div>
  );
};
