import * as React from "react";
import "../assets/css/Footer.css";
import AccessibilityIcon from "../assets/icons/AccessibilityIcon";
import ErrorMessage from "../models/ErrorMessage";
import StatusMessage from "../models/StatusMessage";
import { Link } from "react-router-dom";
import { SURFACE, SurfaceAttributes } from "../Providers/SurfaceProvider";
import { MenuVisibilities } from "./MainBracketWrapper";

interface IProps {
  Environment: NonNullable<string>;
  Surface: SurfaceAttributes;
  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];

  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;

  HiVis: boolean;
  SetHiVis: (val: boolean) => void;

  MenuVisibilities: MenuVisibilities;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
}

class FOOTER extends React.Component<IProps> {
  constructor(props) {
    super(props);

    this.state = {
      show_AccessibilityMenu: false,
      show_HelpMenu: false,
      show_ErrorBlock: false,
      show_StatusBlock: false,
    };
  }

  render() {
    return (
      <div
        className={
          "Footer " +
          ((this.props.HiVis ? "HiVis " : "") + this.props.Surface.CSSClass)
        }
      >
        {this.props.Environment === "DEV" ? (
          <div className="EnvFlag Development">
            Development - {this.props.Surface.RootSurface}
            <div className="ChooseSurface">
              <a
                href={this.props.GetLink(
                  [],
                  [{ Name: "surface", Value: "AOS2" }],
                )}
              >
                AOS
              </a>
              <a
                href={this.props.GetLink(
                  [],
                  [{ Name: "surface", Value: "EAF_LF" }],
                )}
              >
                EAF
              </a>
              <a
                href={this.props.GetLink(
                  [],
                  [{ Name: "surface", Value: "ECF_LF" }],
                )}
              >
                ECF
              </a>
              <a
                href={this.props.GetLink(
                  [],
                  [{ Name: "surface", Value: "ESF_LF" }],
                )}
              >
                ESF
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.Environment === "TEST" ? (
          <div className="EnvFlag Staging">
            Staging - {this.props.Surface.RootSurface}
            <div className="ChooseSurface">
              <a
                href={this.props
                  .GetLink([], [])
                  .replace("ecftest", "agentstest")
                  .replace("esftest", "agentstest")
                  .replace("eaftest", "agentstest")}
              >
                AOS
              </a>
              <a
                href={this.props
                  .GetLink([], [])
                  .replace("ecftest", "eaftest")
                  .replace("esftest", "eaftest")
                  .replace("agentstest", "eaftest")}
              >
                EAF
              </a>
              <a
                href={this.props
                  .GetLink([], [])
                  .replace("eaftest", "ecftest")
                  .replace("esftest", "ecftest")
                  .replace("agentstest", "ecftest")}
              >
                ECF
              </a>
              <a
                href={this.props
                  .GetLink([], [])
                  .replace("eaftest", "esftest")
                  .replace("ecftest", "esftest")
                  .replace("agentstest", "esftest")}
              >
                ESF
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          id="InvisibleMultiCharmWrapper"
          onMouseLeave={() => {
            this.props.SetMenuVisibility(undefined, false);
          }}
        >
          <div
            className="AccessibilityMenu"
            onMouseEnter={() => {
              this.props.SetMenuVisibility("Accessibility", true);
            }}
          >
            <div className="AccessibilityCharm">
              <AccessibilityIcon></AccessibilityIcon>
            </div>
            <div
              className={
                "AccessibilityMenuReveal" +
                (this.props.MenuVisibilities.Accessibility ? " Visible" : "")
              }
            >
              {this.props.Surface.SURFACE === SURFACE.AOS ? (
                <React.Fragment>
                  <h2>Accessibility Information</h2>
                  <p>
                    There is an{" "}
                    <a href="AccessibilityStatementAOS.html" target="_blank">
                      accessibility statement
                    </a>{" "}
                    for the Agent Online Services portal available{" "}
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2>Accessibility</h2>
                  <div>
                    <h3>Zoom</h3>
                    <div>
                      This service should be useable at zoom levels from 50% to
                      300%.
                    </div>
                    <div>
                      To zoom in, use [Ctrl] - [+] or [Ctrl] and [Mousewheel
                      Up].
                    </div>
                    <div>
                      To zoom out, use [Ctrl] - [-] or [Ctrl] and [Mousewheel
                      Down].
                    </div>
                  </div>
                  {/* <div
                    className="AlwaysHiVis"
                    onClick={() => {
                      this.props.SetHiVis(!this.props.HiVis);
                    }}
                  >
                    <h3>High Contrast</h3>
                    <div>Click to set High Contrast mode.</div>
                    <div>
                      {this.props.HiVis
                        ? "High Contrast is ON"
                        : "High Contrast is OFF"}
                    </div>
                  </div> */}
                  <div>
                    <h3>Accessibility Information</h3>
                    <div>
                      {/* Accessibilty Statements are not yet available for UoE-SPO. */}
                      <h2>Accessibility Information</h2>
                      <p>
                        There is an{" "}
                        <a href="/AccessibilityStatement.html" target="_blank">
                          <strong>Accessibility Statement</strong>
                        </a>{" "}
                        for this portal available{" "}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div
            className="HelpMenu"
            onMouseEnter={() => {
              this.props.SetMenuVisibility("Help", true);
            }}
          >
            <a href="#" className="HelpCharm">
              ?
            </a>
            <div
              className={
                "HelpMenuReveal" +
                (this.props.MenuVisibilities.Help ? " Visible" : "")
              }
            >
              {this.props.Surface.SURFACE === SURFACE.AOS ? (
                <React.Fragment>
                  <h2>Submit Feedback or Report a Problem</h2>
                  <p>
                    To contact us about your experiences with the University of
                    Essex Agent Services please e-mail{" "}
                    <a href="mailto:agentenquiries@essex.ac.uk">
                      agentenquiries@essex.ac.uk
                    </a>
                    .
                  </p>
                </React.Fragment>
              ) : this.props.Surface.SURFACE === SURFACE.ECF ? (
                <React.Fragment>
                  <h2>Help / Report an Issue:</h2>
                  <div>
                    <p>
                      Project sponsors in Student Support are your first point
                      of contact:
                    </p>
                    <ul>
                      <li>Andrew Eadie</li>
                      <li>Marie Morris (mm21294@essex.ac.uk)</li>
                    </ul>
                    <p>All other assistance:</p>
                    <ul>
                      <li>SharePoint Help Team (email: sphelp@essex.ac.uk)</li>
                    </ul>
                  </div>
                  {/* Handbooks: ESF: Registry Users -
                  https://edrm.essex.ac.uk/esf/Documents/ESF%20Users%20Guide%20Registry.pdf
                  ESF: Department Users -
                  https://edrm.essex.ac.uk/esf/Documents/ESF%20Users%20Guide%20Departments.pdf */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2>Need Help?</h2>
                  <p>
                    Contact the SharePoint Team by e-mail:{" "}
                    <a
                      className="Link External"
                      href="mailto:sphelp@essex.ac.uk"
                    >
                      sphelp@essex.ac.uk
                    </a>
                    .
                  </p>
                  <p>
                    <strong>See also: </strong>
                  </p>
                  <p>
                    <Link
                      className="Link Internal Antiquated"
                      to={this.props.GetLink(
                        [
                          { Mode: "Utilities", Index: 0 },
                          { Mode: "HelpArticle", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      Frequently Asked Questions (ESF/EAF/ECF)
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="Link Internal Antiquated"
                      to={this.props.GetLink(
                        [
                          { Mode: "Utilities", Index: 0 },
                          { Mode: "KnownIssues", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      Current Known Issues - 28 June 2024 (ESF/EAF/ECF)
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="Link Internal Antiquated"
                      to={this.props.GetLink(
                        [
                          { Mode: "Utilities", Index: 0 },
                          { Mode: "WhatsNew", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      Whats New with EDRM - September 2024 (ESF/EAF/ECF)
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="Link Internal Antiquated"
                      to={this.props.GetLink(
                        [
                          { Mode: "Utilities", Index: 0 },
                          { Mode: "UserGuide", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      User Guide (ESF/EAF/ECF)
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="Link Internal Antiquated"
                      to={this.props.GetLink(
                        [
                          { Mode: "Utilities", Index: 0 },
                          { Mode: "OutlookHelp", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      Outlook App Help Files (ESF/EAF/ECF)
                    </Link>
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </span>
        {this.props.StatusMessages !== undefined &&
        this.props.StatusMessages.length > 0 ? (
          <div
            className="StatusBlock"
            onMouseEnter={() => {
              this.setState({ show_StatusBlock: true });
            }}
            onMouseLeave={() => {
              this.setState({ show_StatusBlock: false });
            }}
          >
            <div className="StatusCharm">?</div>
            <div className="StatusPanelReveal Visible">
              {this.props.StatusMessages.map((th, i) => {
                return (
                  <div className="StatusLine" key={"Error_" + i.toString()}>
                    <div className="StatusReference">{th.Reference}</div>
                    <div className="StatusMessage">{th.Message}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.ErrorMessages !== undefined &&
        this.props.ErrorMessages.length > 0 ? (
          <div
            className="ErrorBlock"
            onMouseEnter={() => {
              this.setState({ show_ErrorBlock: true });
            }}
            onMouseLeave={() => {
              this.setState({ show_ErrorBlock: false });
            }}
          >
            <div className="ErrorCharm">!</div>
            <div className="ErrorPanelReveal Visible">
              {this.props.ErrorMessages.map((th, i) => {
                return (
                  <div className="ErrorLine" key={"Error_" + i.toString()}>
                    <div className="ErrorReference">{th.Reference}</div>
                    <div className="ErrorMessage">{th.Message}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default FOOTER;
