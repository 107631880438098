import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header/Header";
import { FooterWrapper } from "./FooterWrapper";
import IfPermitted from "./IfPermitted";
import { DB_UserResponse } from "../models/DB_UserResponse";
import { DataAccess, User } from "../data/DataAccess";
import ErrorMessage from "../models/ErrorMessage";
import StatusMessage from "../models/StatusMessage";
import {
  PublicClientApplication,
  EndSessionRequest,
} from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import ModeStruct from "../models/ModeStruct";
import TaskListGroup from "../models/TaskListGroup";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { SurfaceAttributes, useSurface } from "../Providers/SurfaceProvider";
import { InvarDataAccess } from "../data/InvarDataAccess";

export interface MenuVisibilities {
  TaskLists: boolean;
  Reports: boolean;
  User: boolean;
  Burger: boolean;
  Environment: boolean;
  Accessibility: boolean;
  Help: boolean;
  Focus: boolean;
  MiniSearch: boolean;
  MiniSearchText: boolean;
}

interface IProps {
  IDA: InvarDataAccess;
  da: DataAccess;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Header_AuthedCharmData?: DB_UserResponse;
  Header_ViewAsCharmData?: DB_UserResponse;
  AuthedUser: User;
  ViewAsUser: User;
  //TODO: Remove this when not required
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  HiVis: boolean;
  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];
  SetHiVis: (val: boolean) => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  MSAL: IMsalContext;
  PCA: PublicClientApplication;
  TailoredLogoutRequest: EndSessionRequest;
  ViewAsNotFound: boolean;
  ViewAsAll: boolean;
  Modes: ModeStruct[];
  TaskListGroups: TaskListGroup[] | undefined;
  Refresher: string;
  Refresh: () => void;
  Mode: string[];
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetTaskList: (TLRef: string) => void;
  SetImpersonation: (alias?: string) => void;
  ThemeName: string;
  SetThemeName: (input: string) => void;
}

export const MainBracketWrapper = (props: IProps) => {
  const Surface: SurfaceAttributes = useSurface();
  const loc = useLocation();

  //  console.log(props);

  useEffect(() => {
    if (
      loc !== undefined &&
      loc.pathname !== undefined &&
      loc.pathname !== ""
    ) {
      props.da.logAccess(Surface.Title);
    }
  }, [loc.pathname]);

  var init: MenuVisibilities = {
    TaskLists: false,
    Reports: false,
    User: false,
    Burger: false,
    Environment: false,
    Accessibility: false,
    Help: false,
    Focus: false,
    MiniSearch: false,
    MiniSearchText: false,
  };

  const [menuVisis, setMenuVisis] = useState(init);
  const [menuTimer, setMenuTimer] = useState(undefined);

  const SetSpecificVisi = (name: string | undefined, newval: boolean) => {
    //  console.log("SetSpecificVisi called:");
    //  console.log(name);
    //  console.log(newval);

    var _nmv = { ...menuVisis };

    if (newval === true) {
      // MiniSearchText is a latch.
      if (name === "MiniSearchText") {
        //  console.log("MiniSearchText set to true -> latching MST and MS to ON.");

        _nmv = { ...init };

        _nmv.MiniSearch = true;
        _nmv.MiniSearchText = true;
      } else if (name === "MiniSearch") {
        // if MiniSearchText is still focused, they both have to stay on even if MiniSearch has been moused out.
        // Only MiniSearchText blur can unset this now.

        //  console.log("MiniSearch set to true ->");

        if (_nmv["MiniSearchText"] === true) {
          //  console.log("MiniSearchText is true, so maintaining latch.");

          _nmv = { ...init };

          _nmv.MiniSearch = true;
          _nmv.MiniSearchText = true;
        } else {
          //  console.log("MiniSearchText is false, so latch doesn't have to be maintained.");

          _nmv = { ...init };

          _nmv.MiniSearch = true;
        }
      } else {
        //  console.log("Neither MST nor MS set to true -> we can follow the standard pattern.");

        _nmv = { ...init };

        if (name !== undefined) {
          _nmv[name] = true;
        }
      }

      clearTimeout(menuTimer);
    } else {
      if (_nmv["MiniSearchText"] === true && name !== "MiniSearchText") {
        //  console.log("a non-MST value set to false, but MST is still true -> maintaining latch.");

        if (name === undefined) {
          _nmv = { ...init };
        } else {
          _nmv[name] = false;
        }

        _nmv.MiniSearchText = true;

        clearTimeout(menuTimer);
      } else if (name === "MiniSearchText") {
        //  console.log("MST set to False, which happens when the textbox blurs. Only set MST to false; leave all other flags to be regulated by other mouse events.");

        _nmv.MiniSearchText = false;
      } else {
        //  console.log("value set to false and MST false -> standard clear");

        _nmv = { ...init };
      }
    }

    if (
      _nmv.TaskLists === false &&
      _nmv.Reports === false &&
      _nmv.User === false &&
      _nmv.Burger === false &&
      _nmv.Environment === false &&
      _nmv.Accessibility === false &&
      _nmv.Help === false &&
      _nmv.Focus === false &&
      _nmv.MiniSearch === false &&
      _nmv.MiniSearchText === false
    ) {
      setMenuTimer(
        setTimeout(() => {
          setMenuVisis(_nmv);
        }, 500),
      );
    } else {
      setMenuVisis(_nmv);
    }
  };

  useEffect(() => {
    //  console.log(menuVisis);
  }, [menuVisis]);

  return (
    <div id="SPOWebUIOnPrem" className={props.ThemeName}>
      <Header
        {...props}
        Title={Surface.Title || ""}
        Permission={props.Permission}
        Header_AuthedCharmData={props.Header_AuthedCharmData}
        Header_ViewAsCharmData={props.Header_ViewAsCharmData}
        MenuVisibilities={menuVisis}
        SetMenuVisibility={SetSpecificVisi}
        SetThemeName={props.SetThemeName}
      />
      <IfPermitted
        ShowDisallowed
        Whitelist={Surface.PermissionsWhitelist || []}
      >
        <div
          className={
            "Body " + ((props.HiVis ? "HiVis " : "") + Surface.CSSClass)
          }
        >
          <Outlet />
        </div>
      </IfPermitted>
      <FooterWrapper
        {...props}
        MenuVisibilities={menuVisis}
        SetMenuVisibility={SetSpecificVisi}
      ></FooterWrapper>
    </div>
  );
};
