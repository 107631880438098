import { ChartMultipleRegular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import { SURFACE } from "../../Providers/SurfaceProvider";
import IfPermitted from "../IfPermitted";
import { IfSurface } from "../IfSurface";
import { User } from "../../data/DataAccess";
import { MenuVisibilities } from "../MainBracketWrapper";

interface IProps {
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  AuthedUser: User;
  ViewAsUser: User;
  MenuVisibilities: MenuVisibilities;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
}

export function ReportingMenu(props: IProps) {
  return (
    <div
      className="ReportingMenu"
      onMouseEnter={() => {
        props.SetMenuVisibility("Reports", true);
      }}
    >
      {/* <a href="#" tabIndex={0} className="ReportingMenuCharm"> */}
      <div tabIndex={0} className="ReportingMenuCharm">
        <ChartMultipleRegular height={30} width={30}></ChartMultipleRegular>
      </div>
      <div
        className={
          "ReportingMenuReveal" +
          (props.MenuVisibilities.Reports ? " Visible" : "")
        }
        aria-expanded={props.MenuVisibilities.Reports}
      >
        <div className="Container">
          <div className="MenuTitle">Reports and Data Operations</div>
          <IfSurface ShowOn={[SURFACE.ESF_COMPLIANCE]}>
            <IfPermitted Whitelist={["Compliance.GDPRReportAuthorised"]}>
              <Link to="/compliance/sar" className="MenuItem">
                GDPR/SAR Report Requests
              </Link>
            </IfPermitted>
          </IfSurface>
          <IfSurface ShowOn={[SURFACE.ESF_COMPLIANCE]}>
            <IfPermitted Whitelist={["Compliance.AuditReportAuthorised"]}>
              <Link to="/compliance/audit" className="MenuItem">
                Home Office Audit Interface
              </Link>
            </IfPermitted>
          </IfSurface>

          <IfSurface ShowOn={[SURFACE.ESF]}>
            <IfPermitted Whitelist={["Collagen.Admins", "Admin.WEBUI"]}>
              <Link
                to={props.GetLink(
                  [
                    { Mode: "AdminPortal", Index: 0 },
                    { Mode: "Permissions", Index: 1 },
                  ],
                  [],
                )}
                className="MenuItem"
              >
                User Access Checker
              </Link>
              <Link
                to={props.GetLink(
                  [
                    { Mode: "AdminPortal", Index: 0 },
                    { Mode: "WAGE", Index: 1 },
                  ],
                  [],
                )}
                className="MenuItem"
              >
                Workflow Assignment Group Editor
              </Link>
            </IfPermitted>
          </IfSurface>

          <IfSurface ShowOn={[SURFACE.EAF_UGEAF]}>
            <IfPermitted Whitelist={["UGEAF.Admissions"]}>
              <Link
                to={props.GetLink(
                  [
                    { Mode: "UGEAF", Index: 0 },
                    { Mode: "Utilities", Index: 1 },
                    { Mode: "RequestPrintView", Index: 2 },
                  ],
                  [],
                )}
                className="MenuItem"
              >
                UCAS/DA Reprint Request
              </Link>
            </IfPermitted>
          </IfSurface>
          <IfSurface ShowOn={[SURFACE.AOS]}>
            <IfPermitted Whitelist={["AOS.Admin"]}>
              <Link
                to={props.GetLink(
                  [
                    { Mode: "Utilities", Index: 0 },
                    { Mode: "NoticeEmbargoManager", Index: 1 },
                  ],
                  [],
                )}
                className="MenuItem"
              >
                Manage Notices and Embargoes
              </Link>
            </IfPermitted>
          </IfSurface>
          <IfSurface ShowOn={[SURFACE.AOS]}>
            <IfPermitted Whitelist={["AOS.Admin"]}>
              <Link
                to={props.GetLink(
                  [
                    { Mode: "Utilities", Index: 0 },
                    { Mode: "LinkedWithdrawals", Index: 1 },
                  ],
                  [],
                )}
                className="MenuItem"
              >
                Linked Withdrawals
              </Link>
            </IfPermitted>
          </IfSurface>
          <IfSurface ShowOn={[SURFACE.AOS]}>
            <IfPermitted Whitelist={["AOS.Admin"]}>
              <Link
                to={props.GetLink(
                  [
                    { Mode: "Utilities", Index: 0 },
                    { Mode: "AgentAccessLog", Index: 1 },
                  ],
                  [],
                )}
                className="MenuItem"
              >
                Agent Access Log
              </Link>
            </IfPermitted>
          </IfSurface>
        </div>
      </div>
    </div>
  );
}
